import React from "react";
import { Route, Routes } from "react-router-dom";
import FeatureRolloutListScreen from "./featureRolloutListScreen";
import FeatureRolloutAddScreen from "./featureRolloutAddScreen";
import Notfound from "../ErrorPages/notFound";
const FeatureRolloutRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<FeatureRolloutListScreen />} />
          <Route index path="/edit/:id" element={<FeatureRolloutAddScreen />} />
          <Route index path="/add" element={<FeatureRolloutAddScreen />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default FeatureRolloutRoute;
