import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import AddProductBuildRollOutComponent from "../../Components/BuildRollOut/addProductBuildRollOutComponent";
import {
  DefaultPagingValue,
  DefaultPagingValueForSearchableDropdown,
} from "../../Constant/Common";
import {
  fromKeyValueArrayToObject,
  fromObjectToKeyValueArray,
  isJson,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationService from "../../Services/notification";
import { useDispatch } from "react-redux";
import { setAllTargetOperatorsDetails } from "../../Store/Reducers/Slices/LookUp/lookupSlice";
import { DUPLICATE_FORCE_INSERT } from "../../Constant/HttpStatus";

const AddBuildRollOutContainer = React.forwardRef(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [loading, setLoading] = useState(false);
  const [productBuild, SetProductBuild] = useState([]);
  const [productTargets, setProductTargets] = useState([]);
  const [targetOperators, setTargetOperators] = useState([]);
  const [addEditBuildRollOut, setAddEditBuildRollOut] = useState();
  const { allTargetOperators } = useSelector((state) => state.lookup);
  const [productAttributes, setProductAttributes] = useState(null);
  const [showDuplicationPopUp, setShowDuplicationPopUp] = useState();
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState();
  const [paging, setPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const editBuildRolloutDataById = async (id) => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_PRODUCT_BUILD_ROLLOUT_BY_ID.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace(
                "#{environmentId}",
                productState?.selectedEnvironment?.id
              )
              .replace("#{rolloutId}", id)}`
          );

          if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
            if (response?.data?.updateBuildMeta) {
              response.data.updateBuildMeta = fromObjectToKeyValueArray(
                response.data.updateBuildMeta
              );
            }
            if (isJson(response?.data?.targetCriteriaJson)) {
              response.data.queryJson = JSON.parse(
                response.data.targetCriteriaJson
              );
              setAddEditBuildRollOut(response.data);
            }
          } else {
            backToListPage();
          }
        } catch (error) {
          // Handle error if needed
        } finally {
          setLoading(false);
        }
      };
      const editBuildRolloutDataByIdForCopy = async (state) => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_PRODUCT_BUILD_ROLLOUT_BY_ID.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace("#{environmentId}", state.environmentId)
              .replace("#{rolloutId}", state.id)}`
          );

          if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
            if (response?.data?.updateBuildMeta) {
              response.data.updateBuildMeta = fromObjectToKeyValueArray(
                response.data.updateBuildMeta
              );
            }
            if (isJson(response?.data?.targetCriteriaJson)) {
              response.data.queryJson = JSON.parse(
                response.data.targetCriteriaJson
              );
              const responseData = response.data;

              const updatedData = {
                partnerDownloadUrl: responseData.partnerDownloadUrl,
                noUpdateableBuildMeta: {
                  name: responseData.noUpdateableBuildMeta?.name,
                },
                productId: responseData.productId,
                environmentId: responseData.environmentId,
                buildId: null,
                cdCiIdentifier: responseData.cdCiIdentifier,
                name: state.name,
                description: responseData.description,
                isEnabled:
                  responseData.isEnabled !== undefined
                    ? responseData.isEnabled
                    : false,
                downloadUrl: responseData.downloadUrl,
                updateBuildMeta: responseData.updateBuildMeta,
                targets: [],
                targetCriteria: responseData.targetCriteria,
                targetCriteriaJson: responseData.targetCriteriaJson,
                updatedBy: responseData.updatedBy,
                updatedAt: responseData.updatedAt,
                queryJson: responseData.queryJson,
              };

              setAddEditBuildRollOut(updatedData);
            }
          } else {
            backToListPage();
          }
        } catch (error) {
          // Handle error if needed
        } finally {
          setLoading(false);
        }
      };

      const fetchAllProductBuild = async () => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_ALL_PRODUCT_BUILDS.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace(
                "#{environmentId}",
                productState?.selectedEnvironment?.id
              )}?PageNumber=${
              DefaultPagingValueForSearchableDropdown.PAGE_INDEX
            }&PageSize=${
              DefaultPagingValueForSearchableDropdown.PAGE_SIZE
            }&isEnabled=true`
          );

          if (
            isSuccessStatus(response?.status) &&
            !isNullOrEmpty(response?.data)
          ) {
            SetProductBuild(response?.data?.result);
          }
        } catch (error) {
          // Handle error if needed
        } finally {
          setLoading(false);
        }
      };

      const fetchAllProductTarget = async () => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_ALL_PRODUCT_TARGETS.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace(
                "#{environmentId}",
                productState?.selectedEnvironment?.id
              )}?PageNumber=${
              DefaultPagingValueForSearchableDropdown.PAGE_INDEX
            }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
          );

          if (
            isSuccessStatus(response?.status) &&
            !isNullOrEmpty(response?.data)
          ) {
            setProductTargets(response?.data?.result);
          }
        } catch (error) {
          // Handle error if needed
        } finally {
          setLoading(false);
        }
      };
      if (id) await editBuildRolloutDataById(id);
      if (state) await editBuildRolloutDataByIdForCopy(state);
      await fetchAllProductTarget();
      await fetchAllProductBuild();
      // await fetchAllTargetOperators();
    };

    fetchData();
  }, [id, productState, authState, state]);

  useEffect(() => {
    if (allTargetOperators) {
      setTargetOperators(allTargetOperators);
    } else {
      fetchAllTargetOperators();
    }
  }, [allTargetOperators]);

  const fetchAllTargetOperators = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(
        `${Urls.LOOKUP_TARGET_TARGETOPERATORS}`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        dispatch(
          setAllTargetOperatorsDetails({
            allTargetOperators: response?.data?.map((item) => ({
              name: item,
              label: item,
            })),
          })
        );
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setLoading(false);
    }
  };

  const buildUrl = (
    baseUrl,
    tenantId,
    productId,
    environmentId,
    rolloutId = ""
  ) => {
    return baseUrl
      .replace("#{tenantId}", tenantId)
      .replace("#{productId}", productId)
      .replace("#{environmentId}", environmentId)
      .replace("#{rolloutId}", rolloutId);
  };

  const handleApiRequest = async (method, url, data) => {
    try {
      setLoading(true);
      const response = await httpClient[method](url, data);

      if (isSuccessStatus(response?.status)) {
        const message =
          method === "put"
            ? " Release edit successfully"
            : " Release added successfully";
        NotificationService.success("Success", message);
        backToListPage();
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.length > 0 &&
        error?.response?.data?.errors[0]?.code === DUPLICATE_FORCE_INSERT
      ) {
        setDuplicateErrorMessage(error?.response?.data?.errors[0]?.message);
        if (data?.updateBuildMeta) {
          data.updateBuildMeta = fromObjectToKeyValueArray(
            data.updateBuildMeta
          );
        }
        if (isJson(data?.targetCriteriaJson)) {
          data.queryJson = JSON.parse(data.targetCriteriaJson);
          setAddEditBuildRollOut(data);
        }
        setAddEditBuildRollOut(data);
        setShowDuplicationPopUp(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const addBuildRollOutApi = async (data) => {
    if (data.updateBuildMeta) {
      data.updateBuildMeta = fromKeyValueArrayToObject(data.updateBuildMeta);
    }

    const tenantId = authState?.accountData?.tenantId;
    const productId = productState?.selectedProduct?.id;
    const environmentId = productState?.selectedEnvironment?.id;

    const url = data.id
      ? buildUrl(
          Urls.TENANT_UPDATE_PRODUCT_BUILD_ROLLOUT,
          tenantId,
          productId,
          environmentId,
          data.id
        )
      : buildUrl(
          Urls.TENANT_ADD_PRODUCT_BUILD_ROLLOUT,
          tenantId,
          productId,
          environmentId
        );

    const method = data.id ? "put" : "post";
    await handleApiRequest(method, url, data);
  };
  const backToListPage = () => {
    navigate("/product/release");
  };

  useEffect(() => {
    fetchAllTargetAttributes();
  }, [paging.pageIndex, paging.pageSize]);

  const fetchAllTargetAttributes = async () => {
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_TARGET_ATTRIBUTES.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace(
        "#{productId}",
        productState?.selectedProduct?.id
      )}?PageNumber=${paging.pageIndex}&PageSize=${paging.pageSize}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setProductAttributes(
        response.data?.result?.map((item) => {
          return {
            value: item.name,
            label: item.name,
            type: item.type,
          };
        })
      );
      if (response.data?.pagingStrategy) {
        setPaging({
          pageIndex: response.data?.pagingStrategy?.currentPage,
          pageSize: response.data?.pagingStrategy?.pageSize,
          totalPages: response.data?.pagingStrategy?.totalPages,
        });
      }
      if (response.data?.result?.length <= 0) {
        setPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_INDEX,
        });
      }
    } else {
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AddProductBuildRollOutComponent
          onFinish={(data) => addBuildRollOutApi(data)}
          productTargets={productTargets?.filter((target) => target.isEnabled)}
          targetOperators={targetOperators}
          editBuildRolloutData={addEditBuildRollOut}
          productBuildArray={productBuild}
          backToListPage={backToListPage}
          targetAttributeDropdownData={productAttributes}
          showDuplicationPopUp={showDuplicationPopUp}
          setShowDuplicationPopUp={setShowDuplicationPopUp}
          duplicateErrorMessage={duplicateErrorMessage}
        />
      )}
    </>
  );
});

export default AddBuildRollOutContainer;

