import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Popconfirm, Select, Space, Table } from "antd";
import React, { useEffect } from "react";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import { ENABLE_OPTIONS } from "../../Constant/Common";

const ListContentRolloutsComponent = (props) => {
  const {
    editContentRolloutData,
    deleteContentRollout,
    allContentTemplates,
    ContentRollouts,
    productBuildArray,
    listFilterSubmit,
    handlePageChange,
    listFilterData,
    resetFilter,
    paging,
  } = props;
  const [listFilter] = Form.useForm();

  useEffect(() => {
    listFilter.setFieldsValue(listFilterData);
  }, [listFilterData, listFilter]);

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
    {
      name: "ContentTemplateName",
      component: (
        <Select
          allowClear
          id="templateId"
          placeholder="Content Template Name"
          className="list-filter-common-style"
        >
          {allContentTemplates?.map((template) => (
            <Select.Option key={template.id} value={template.name}>
              {template.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "BuildName",
      component: (
        <Select
          allowClear
          id="selectBuildVersion"
          placeholder="Build Name"
          className="list-filter-common-style"
        >
          {productBuildArray?.map((build) => (
            <Select.Option key={build.id} value={build.name}>
              {build.name} {`( ${build.version} )`}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "IsEnable",
      component: (
        <Select
          allowClear
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enable?"
        >
          {ENABLE_OPTIONS?.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  // Updated table columns based on the provided object structure
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Template Name",
      dataIndex: "contentTemplateName",
      key: "contentTemplateName",
    },
    {
      title: "Template Type",
      dataIndex: "templateType",
      key: "templateType",
    },
    {
      title: "Build Name",
      dataIndex: "buildName",
      key: "buildName",
    },
    {
      title: "Enabled",
      dataIndex: "isEnable",
      key: "isEnable",
      render: (isEnable) => (isEnable ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space style={{ width: "200px" }}>
            <ShowForRightAccessComponent>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editContentRolloutData(record);
                }}
              />
              <Divider type="vertical" />
            </ShowForRightAccessComponent>
            <ShowForRightAccessComponent>
              <Popconfirm
                title="Are you sure to delete this content rollout?"
                onConfirm={() => {
                  deleteContentRollout(record);
                }}
              >
                <DeleteOutlined title="Delete" />
              </Popconfirm>
            </ShowForRightAccessComponent>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <Divider />
      <Table
        dataSource={ContentRollouts?.result}
        pagination={{
          showTotal: () => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};

export default ListContentRolloutsComponent;

