import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Form } from "antd";
import './SearchComponent.scss'; 

const SearchComponent = ({
  handleSearch,
  handleReset,
  clearFilters,
  close,
  selectedKeys,
  setSelectedKeys,
  searchInput,
  confirm,
  dataIndex,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleSearch([values.search], confirm, dataIndex); 
  };

  const handleClose = () => {
    form.resetFields();
    close(); 
  };

  const handleResetButton = () => {
    handleReset()
  }

  return (
    <div className="search-container" onKeyDown={(e) => e.stopPropagation()}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ search: selectedKeys[0] ?? searchInput }} 
      >
        <Form.Item
          name="search"
          className="margin-10"
          rules={[
            {
              required: true,
              message: "Please enter product name", 
            },
          ]}
        >
          <Input
            autoFocus
            placeholder="Search product name / code"
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              if (!e.target.value) {
                handleSearch([], confirm, dataIndex);
              }
            }}
            onPressEnter={() => form.submit()} 
            className="search-input"
          />
        </Form.Item>

        <Space>
          <Button
            type="primary"
            htmlType="submit" 
            icon={<SearchOutlined />}
            size="small"
            className="search-button" 
            id="search-button-product-search"  
            data-testid="search-button-product-search"  
          >
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
              clearFilters && handleReset(clearFilters);
            }}
            size="small"
            className="reset-button"  
            id="reset-button-product-search"  
            data-testid="reset-button-product-search" 
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={
              handleClose
            }
            className="close-button" 
            id="close-button-product-search" 
            data-testid="close-button-product-search" 
          >
            Close
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default SearchComponent;
