import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setProductDetails,
  setSelectedEnvironmentDetails,
} from "../../Store/Reducers/Slices/Product/productSlice";
import { Breadcrumb, Button, Divider, Input, List, Tag, Tooltip } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  debounceForSearchApiCall,
  getLocalStorage,
  getProductIcon,
  THEME_KEY,
} from "../../Util/commonUtility";
import { useLocation } from "react-router-dom";
import { httpClient } from "../../Api/client";
import Urls from "../../Constant/Urls";

const BreadCrumb = ({ breadCrumbItems, defaultValue, selection }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const panelRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const selectedEnvironmentName = productState?.selectedEnvironment?.name;
  const selectedProductEnvironments =
    productState?.selectedProduct?.environments;
  const [selectedProduct, setSelectedProduct] = useState(
    productState?.selectedProduct
  );
  const selectedProductName = productState?.selectedProduct?.name;
  const isDarkMode = getLocalStorage(THEME_KEY) || false;
  const [selectedEnvironment, setSelectedEnvironment] = useState(
    productState?.selectedEnvironment
  );
  // eslint-disable-next-line no-unused-vars
  const [pagingValues, SetPagingValues] = useState({
    pageSize: 100,
    pageNumber: 1,
  });
  const [allProductsData, setAllProductsData] = useState([]);
  const [filteredProductList, setFilteredProductList] =
    useState(allProductsData);

  const handleMenuClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDone = () => {
    setDropdownVisible(false);
    dispatch(
      setProductDetails({
        selectedProduct: selectedProduct,
        allProducts: productState?.allProducts,
      })
    );

    if (selectedEnvironment?.id !== productState?.selectedEnvironment?.id) {
      dispatch(
        setSelectedEnvironmentDetails({
          selectedProductId: selectedProduct?.id,
          selectedEnvironmentId: selectedEnvironment?.id,
        })
      );
    }
  };

  const handleClickOutside = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const handleProductSelect = (product) => {
    if (product.environments.length === 1) {
      setSelectedEnvironment(product.environments[0]);
    } else {
      setSelectedEnvironment(product?.environments[0]);
    }
    setSelectedProduct(product);
  };

  const handleEnvironmentSelect = (environment) => {
    setSelectedEnvironment(environment);
  };

  const getAllProducts = async (searchText) => {
    try {
      const response = await httpClient.get(
        `${Urls.TENANT_GET_ALL_PRODUCTS.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )}?PageNumber=${pagingValues.pageNumber}&PageSize=${
          pagingValues.pageSize
        }${searchText ? `&SearchText=${searchText}` : ``}`
      );
      setAllProductsData(response.data?.result);
      setFilteredProductList(response?.data?.result);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounceForSearchApiCall((searchText) => {
      getAllProducts(searchText);
    }, 500),
    []
  );

  const handleSearch = (e) => {
    const searchedValue = e.target.value.toLowerCase();
    setSearchTerm(searchedValue);

    const filteredList = allProductsData?.filter((product) =>
      product.name.toLowerCase().includes(searchedValue)
    );
    setFilteredProductList(filteredList.length > 0 ? filteredList : []);

    debouncedSearch(searchedValue || "");
  };

  useEffect(() => {
    getAllProducts("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      setSelectedProduct(productState?.selectedProduct);
      setSelectedEnvironment(productState?.selectedEnvironment);
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible]);

  return (
    <div>
      {breadCrumbItems?.map((breadcrumb) => {
        const isProductPage =
          location.pathname.includes("/product") &&
          !location.pathname.includes("/products/add") &&
          !location.pathname.includes("/products/edit");
        const AddBreadCrumbItem =
          breadcrumb.name === "Product" && isProductPage;

        return (
          AddBreadCrumbItem && (
            <>
              <div
                className={`breadcrumb-container ${
                  dropdownVisible ? "expanded" : ""
                }`}
                onClick={handleMenuClick}
              >
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>All Products</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {(selectedProductName || selectedProduct?.name)?.length >=
                    50 ? (
                      <Tooltip
                        title={selectedProductName || selectedProduct?.name}
                      >
                        {(selectedProductName || selectedProduct?.name).slice(
                          0,
                          47
                        )}
                        ...
                      </Tooltip>
                    ) : (
                      selectedProductName || selectedProduct?.name
                    )}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Tag color="blue" className="breadcrumb-tag">
                      {selectedEnvironmentName || selectedEnvironment?.name}
                    </Tag>
                  </Breadcrumb.Item>
                </Breadcrumb>
                <DownOutlined
                  style={{
                    float: "right",
                    transition: "transform 0.3s",
                    transform: dropdownVisible
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </div>
              {dropdownVisible && (
                <div className="dropdown-wrapper" ref={panelRef}>
                  <div className="dropdown-search">
                    <Input
                      placeholder="Search Product"
                      value={searchTerm}
                      onChange={handleSearch}
                      className="search-box"
                      allowClear
                      onClear={() => {
                        setSearchTerm("");
                        setFilteredProductList(allProductsData);
                      }}
                    />
                  </div>
                  <Divider className="horizontal-divider" type="horizontal" />
                  <div className="list-container">
                    <div className="list-column">
                      <List
                        header={<div className="list-header">Products</div>}
                        dataSource={filteredProductList}
                        className="list-wrapper"
                        renderItem={(product) => (
                          <List.Item
                            className="product-item"
                            onClick={() => handleProductSelect(product)}
                            style={{
                              background:
                                selectedProduct?.name === product.name
                                  ? isDarkMode
                                    ? "#333333"
                                    : "#EAEAEA"
                                  : "",
                            }}
                          >
                            <div className="list-item">
                              <span style={{ marginRight: "10px" }}>
                                {getProductIcon(product.type)}
                              </span>
                              <span className="list-item-name">
                                {product?.name?.length >= 40 ? (
                                  <Tooltip title={product.name}>
                                    {product.name.slice(0, 47)}...
                                  </Tooltip>
                                ) : (
                                  product.name
                                )}
                              </span>
                              {selectedProduct?.id === product?.id && (
                                <span className="list-item-icon">
                                  <RightOutlined />
                                </span>
                              )}
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>

                    <Divider className="list-divider" type="vertical" />

                    <div className="list-column">
                      <List
                        header={<div className="list-header">Environments</div>}
                        className="list-wrapper"
                        dataSource={
                          selectedProduct?.environments ||
                          selectedProductEnvironments
                        }
                        renderItem={(env) => (
                          <List.Item
                            className="env-item"
                            style={{
                              background:
                                selectedEnvironment?.name === env.name
                                  ? isDarkMode
                                    ? "#333333"
                                    : "#EAEAEA"
                                  : "",
                            }}
                            onClick={() => handleEnvironmentSelect(env)}
                          >
                            <div className="list-item">{env?.name}</div>
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>

                  <div className="done-button-container">
                    <span>
                      <Button type="primary" onClick={handleDone}>
                        Done
                      </Button>
                    </span>
                  </div>
                </div>
              )}
            </>
          )
        );
      })}
    </div>
  );
};

export default BreadCrumb;
