import React, { useEffect, useState } from "react";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Loader from "../../Components/Common/Loader";
import AddProductTargetComponent from "../../Components/ProductTarget/addProductTarget";

import NotificationService from "../../Services/notification";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isJson, isNullOrEmpty } from "../../Util/commonUtility";
const AddTargetContainer = React.forwardRef((props, ref) => {
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [addEditTargetData, setAddEditTargetData] = useState();

  useEffect(() => {
    const getProductTargetByTargetId = async (id) => {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_TARGET_BY_TARGET_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)
          .replace("#{targetId}", id)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        if (isJson(response?.data?.targetConditionJson)) {
          response.data.queryJson = JSON.parse(
            response.data.targetConditionJson
          );
        }
        setAddEditTargetData(response.data);
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    const getProductTargetByTargetIdForCopy = async (state) => {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_TARGET_BY_TARGET_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", state.environmentId)
          .replace("#{targetId}", state.id)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        if (isJson(response?.data?.targetConditionJson)) {
          response.data.queryJson = JSON.parse(
            response.data.targetConditionJson
          );
        }
        const responseData = response.data;
        const Data = {
          name: state.name,
          isEnabled: responseData?.isEnabled,
          description: responseData?.description,
          targetCondition: responseData?.targetCondition,
          targetConditionJson: responseData?.targetConditionJson,
          queryJson: responseData?.queryJson,
        };
        setAddEditTargetData(Data);
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    if (id) getProductTargetByTargetId(id);
    if (state) getProductTargetByTargetIdForCopy(state);
  }, [id, authState, productState, state]);

  const addUpdateProductTarget = async (data) => {
    if (data.id) {
      setShowLoading(true);
      const response = await httpClient.put(
        Urls.TENANT_UPDATE_TARGET.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)
          .replace("#{targetId}", data.id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        NotificationService.success("Success", "Target edit successfully");
        backToListPage();
      }
    } else {
      const response = await httpClient.post(
        Urls.TENANT_ADD_TARGET.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        NotificationService.success("Success", "Target added successfully");
        backToListPage();
      }
    }
    setShowLoading(false);
  };

  const backToListPage = () => {
    navigate("/product/target");
  };
  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddProductTargetComponent
          addEditTargetData={addEditTargetData}
          backToListPage={backToListPage}
          onFinish={(data) => addUpdateProductTarget(data)}
          //updatedInfo = {updatedInfo}
        />
      )}
    </>
  );
});
export default AddTargetContainer;

