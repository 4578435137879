import React from "react";
import { Route, Routes } from "react-router-dom";
import ReleaseListScreen from "./releaseListScreen";
import ReleaseAddScreen from "./releaseAddScreen";
import Notfound from "../ErrorPages/notFound";
const ReleaseRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<ReleaseListScreen />} />
          <Route index path="/add" element={<ReleaseAddScreen />} />
          <Route index path="/edit/:id" element={<ReleaseAddScreen />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default ReleaseRoute;
