import { useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import ListProductFeatureFlagComponent from "../../Components/FeatureFlag/listProductFeatureFlagComponent";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { convertDataToQueryParam } from "../../Util/commonUtility";
import { DefaultPagingValue } from "../../Constant/Common";
import { useNavigate } from "react-router-dom";
import NotificationService from "../../Services/notification";
import useFetchData from "../../Hooks/useFetchData";
const ListFeatureFlagContainer = () => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [featureFlagFilterData, setFeatureFlagFilterData] = useState({});
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  // Fetch data using useFetchData
  const {
    data: productFeatureFlags,
    loading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_FEATURE_FLAG.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    ).replace(
      "#{productId}",
      productState?.selectedProduct?.id
    )}?${convertDataToQueryParam(featureFlagFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      featureFlagFilterData,
      productState.selectedProduct?.id,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const deleteProductFeatureFlag = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_PRODUCT_FEATURE_FLAG.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{featureFlagId}", data.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success("Success", " Feature deleted successfully.");
      setFeatureFlagFilterData({}); // Reset filter to reflect changes
    } 
  };

  const handlePageChange = (nextPage, pageSize) => {
    setPagination((prevPaging) => ({
      ...prevPaging,
      pageSize: pageSize,
      pageIndex: nextPage,
    }));
  };

  const editProductFeatureFlag = (data) => {
    navigate(`/product/featureFlag/edit/${data.id}`);
  };

  const featureFlagFilterSubmit = (data) => {
    setFeatureFlagFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  const resetFilter = () => {
    setFeatureFlagFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 }));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ListProductFeatureFlagComponent
          productFeatureFlags={productFeatureFlags}
          featureFlagFilterData={featureFlagFilterData}
          paging={pagination}
          editProductFeatureFlag={(data) => editProductFeatureFlag(data)}
          deleteProductFeatureFlag={(data) => deleteProductFeatureFlag(data)}
          featureFlagFilterSubmit={featureFlagFilterSubmit}
          resetFilter={resetFilter}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};
export default ListFeatureFlagContainer;
