import { httpClient, isSuccessStatus } from "../Api/client";
import { isNullOrEmpty } from "../Util/commonUtility";
import Urls from "../Constant/Urls";

export const fetchAllItems = async (
  type,
  setItems,
  authState,
  productState,
  environmentId
) => {
  const urlMap = {
    features: Urls.TENANT_GET_ALL_PRODUCT_FEATURE_FLAG,
    contents: Urls.TENANT_GET_ALL_CONTENT_TEMPLATES,
    targets: Urls.TENANT_GET_ALL_PRODUCT_TARGETS,
    release: Urls.TENANT_DELETE_PRODUCT_BUILD_ROLLOUT,
    featureRollouts: Urls.TENANT_GET_ALL_PRODUCT_FEATURE_ROLLOUT,
    contentRollouts: Urls.TENANT_GET_ALL_CONTENT_ROLLOUTS,
  };

  const url = urlMap[type]
    .replace("#{tenantId}", authState?.accountData?.tenantId)
    .replace("#{productId}", productState?.selectedProduct?.id)
    .replace("#{environmentId}", environmentId || "");

  try {
    const queryParams = new URLSearchParams({
      PageSize: "1000",
    });

    if (
      environmentId &&
      (type === "featureRollouts" || type === "contentRollouts")
    ) {
      queryParams.append("environmentId", environmentId);
    }

    const response = await httpClient.get(`${url}?${queryParams}`);
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setItems(response.data.result);
    } else {
      setItems([]);
    }
  } catch (error) {
    console.error(`Error fetching ${type}:`, error);
    setItems([]);
  }
};

export const handleFormFinish = (formType, values, handleOk) => {
  handleOk(formType, values);
};

export const getFormFieldValue = (form, field) => {
  return form.getFieldValue(field);
};

export const findEnvironmentName = (environments, environmentId) => {
  return environments?.find((obj) => obj.id === environmentId)?.name;
};

