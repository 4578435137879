import config from "../Util/config";

const Urls = {
  ACCOUNT_LOGIN: "auth/login",
  ACCOUNT_REFRESHTOKEN: "token/refreshtoken",
  ACCOUNT_CHANGEPASSWORD: "accounts/#{accountId}/changepassword",
  ACCOUNT_PROFILE: "accounts/#{accountId}",
  API_1: "",

  TENANT_GET_ALL_TENANTS_USERS: "tenants/#{tenantId}/users",
  TENANT_ADD_TENANT_USER: "tenants/#{tenantId}/users",
  TENANT_DELETE_TENANT_USER: "tenants/#{tenantId}/users/#{userId}",
  TENANT_RESET_TENANT_USER_PASSWORD:
    "tenants/#{tenantId}/users/#{userId}/resetpassword",

  TENANT_ADD_PRODUCT: "tenants/#{tenantId}/products",
  TENANT_GET_ALL_PRODUCTS: "tenants/#{tenantId}/products",
  TENANT_GET_PRODUCT_BY_ID: "tenants/#{tenantId}/products/#{productId}",
  TENANT_UPDATE_PRODUCT_BY_ID: "tenants/#{tenantId}/products/#{productId}",
  TENANT_DELETE_PRODUCT_BY_ID: "tenants/#{tenantId}/products/#{productId}",

  TENANT_ADD_PRODUCT_USERS: "tenants/#{tenantId}/products/#{productId}/users",
  TENANT_GET_ALL_PRODUCT_USERS:
    "tenants/#{tenantId}/products/#{productId}/users",
  TENANT_DELETE_PRODUCT_USER:
    "tenants/#{tenantId}/products/#{productId}/users/#{userId}",
  TENANT_UPDATE_PRODUCT_USER:
    "tenants/#{tenantId}/products/#{productId}/users/#{userId}",
  TENANT_GET_PRODUCT_USER_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/users/#{userId}",

  TENANT_GET_ALL_PRODUCT_ENVIRONMENTS:
    "tenants/#{tenantId}/products/#{productId}/environments",

  TENANT_ADD_PRODUCT_TARGET_ATTRIBUTES:
    "tenants/#{tenantId}/products/#{productId}/targetattributes",
  TENANT_UPDATE_PRODUCT_TARGET_ATTRIBUTES_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/targetattributes/#{targetAttributeId}",
  TENANT_GET_ALL_PRODUCT_TARGET_ATTRIBUTES:
    "tenants/#{tenantId}/products/#{productId}/targetattributes",
  TENANT_GET_PRODUCT_TARGET_ATTRIBUTE_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/targetattributes/#{targetAttributeId}",
  TENANT_DELETE_PRODUCT_TARGET_ATTRIBUTES:
    "tenants/#{tenantId}/products/#{productId}/targetattributes/#{targetAttributeId}",

  TENANT_GET_ALL_PRODUCT_TARGETS:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets",
  TENANT_UPDATE_TARGET:
    "/tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets/#{targetId}",
  TENANT_ADD_TARGET:
    "/tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets",
  TENANT_DELETE_TARGET:
    "/tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets/#{targetId}",
  TENANT_GET_TARGET_BY_TARGET_ID:
    "/tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets/#{targetId}",
  TENANT_GET_TARGET_QUERY:
    "/tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets/#{targetId}",
  TENANT_GET_TARGET_TEST:
    "/tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets/#{targetId}/test",
  TENANT_ADD_TARGET_COPIED_URL:
    "/tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/targets/#{targetId}/copy",

  TENANT_TEST_DISTRIBUTION: "/cfu",

  TENANT_GET_ALL_PRODUCT_BUILDS:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/builds",
  TENANT_ADD_PRODUCT_BUILD:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/builds",
  TENANT_DELETE_PRODUCT_BUILD:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/builds/#{buildId}",
  TENANT_UPDATE_PRODUCT_BUILD:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/builds/#{buildId}",
  TENANT_GET_PRODUCT_BUILD_ID:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/builds/#{buildId}",

  TENANT_ADD_PRODUCT_BUILD_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/buildrollouts",
  TENANT_GET_ALL_PRODUCT_BUILD_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/buildrollouts",
  TENANT_GET_PRODUCT_BUILD_ROLLOUT_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/buildrollouts/#{rolloutId}",
  TENANT_UPDATE_PRODUCT_BUILD_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/buildrollouts/#{rolloutId}",
  TENANT_DELETE_PRODUCT_BUILD_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/buildrollouts/#{rolloutId}",
  TENANT_GET_BUILD_ROLLOUT_TEST:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/buildrollouts/#{rolloutId}/test",
  TENANT_ADD_BUILD_ROLLOUT_COPIED_URL:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/buildrollouts/#{rolloutId}/copy",

  TENANT_TARGET_CONDITION: "lookups/targetconditions/#{TargetAttributeType}",
  LOOKUP_GET_TARGET_CONDITIONS_BY_TARGETATTRIBUTETYPE:
    "lookups/targetconditions/#{TargetAttributeType}",
  LOOKUP_TARGET_TARGETOPERATORS: "lookups/targetoperators",

  TENANT_GET_ALL_PRODUCT_FEATURE_FLAG:
    "tenants/#{tenantId}/products/#{productId}/features",
  TENANT_ADD_PRODUCT_FEATURE_FLAG:
    "tenants/#{tenantId}/products/#{productId}/features",
  TENANT_EDIT_PRODUCT_FEATURE_FLAG:
    "tenants/#{tenantId}/products/#{productId}/features/#{featureFlagId}",
  TENANT_GET_PRODUCT_FEATURE_FLAG_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/features/#{featureFlagId}",
  TENANT_DELETE_PRODUCT_FEATURE_FLAG:
    "tenants/#{tenantId}/products/#{productId}/features/#{featureFlagId}",

  TENANT_GET_ALL_PRODUCT_FEATURE_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/featuresrollouts",
  TENANT_ADD_PRODUCT_FEATURE_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/featuresrollouts",
  TENANT_EDIT_PRODUCT_FEATURE_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/featuresrollouts/#{featureRolloutId}",
  TENANT_GET_PRODUCT_FEATURE_ROLLOUT_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/featuresrollouts/#{featureRolloutId}",
  TENANT_DELETE_PRODUCT_FEATURE_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/featuresrollouts/#{featureRolloutId}",
  TENANT_COPY_PRODUCT_FEATURE_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/featuresrollouts/#{featureRolloutId}/copy",
  TENANT_TEST_BUILD_ROLLOUT: "rollouttest/builds",
  TENANT_TEST_BUILD_FEATURE: "rollouttest/features",
  TENANT_TEST_BUILD_CONTENT: "rollouttest/contents",

  TENANT_ADD_CONTENT_TEMPLATE:
    "tenants/#{tenantId}/products/#{productId}/contentTemplates",
  TENANT_GET_ALL_CONTENT_TEMPLATES:
    "tenants/#{tenantId}/products/#{productId}/contentTemplates",
  TENANT_GET_CONTENT_TEMPLATE_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/contentTemplates/#{templateId}",
  TENANT_UPDATE_CONTENT_TEMPLATE:
    "tenants/#{tenantId}/products/#{productId}/contentTemplates/#{templateId}",
  TENANT_DELETE_CONTENT_TEMPLATE:
    "tenants/#{tenantId}/products/#{productId}/contentTemplates/#{templateId}",
  TENANT_LANGUAGE_LOOKUP: "lookups/language",

  TENANT_ADD_CONTENT_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/contentRollouts",
  TENANT_GET_ALL_CONTENT_ROLLOUTS:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/contentRollouts",
  TENANT_CONTENT_ROLLOUT_COPY:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/contentRollouts/#{rolloutId}/copy",
  TENANT_GET_CONTENT_ROLLOUT_BY_ID:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/contentRollouts/#{rolloutId}",
  TENANT_UPDATE_CONTENT_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/contentRollouts/#{rolloutId}",
  TENANT_DELETE_CONTENT_ROLLOUT:
    "tenants/#{tenantId}/products/#{productId}/environments/#{environmentId}/contentRollouts/#{rolloutId}",
  TENANT_ADD_FILE: "tenants/#{tenantId}/products/#{productId}/files/upload",
  TENANT_GET_ALL_FILE: "/tenants/#{tenantId}/products/#{productId}/files",
  CONTENT_TEMPLATE_PREVIEW:
    "/tenants/#{tenantId}/products/#{productId}/preview",
  XAML_TEMPLATE_PREVIEW: `xamlurl:${config.xamlUrl}tenants/#{tenantId}/products/#{productId}/preview/#{contentKey}`,
};

export default Urls;

