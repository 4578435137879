import React, { useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import BuildRoute from "./buildRoute";
import ProductInfoTabLayout from "../../Components/Common/productInfoTabLayout";
import ReleaseRoute from "./releaseRoute";
import { Space, Modal, Button } from "antd";
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  SwapOutlined,
  AimOutlined,
  ApartmentOutlined,
  UsergroupAddOutlined,
  FlagOutlined,
  RiseOutlined,
  NotificationOutlined,
  FundViewOutlined,
  FileSearchOutlined,
  CopyOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TargetRoute from "./targetRoute";

import TestContainer from "../../Containers/Test/Test";
import DetailsContainer from "../../Containers/ProductDetails/DetailsContainer";
import TargetAttributesRoute from "../ProductDetails/TargetAttributesRoute";
import UsersRoute from "../ProductDetails/usersRoute";
import { Role } from "../../Constant/Common";
import FeatureFlagRoute from "./featureFlagRoute";
import FeatureRolloutRoute from "./featureRolloutRoute";
import ContentTemplatesRoute from "./contentTemplatesRoute";
import ContentRolloutRoutes from "./contentRolloutRoutes";
import FileRoute from "./fileRoute";
import { useExtraButtons } from "../../Hooks/useExtraButton";
import { useCopyModal } from "../../Hooks/useCopyButton";
import { useTabNavigation } from "../../Hooks/useTabNavigation";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";

const ProductInfoRoutes = () => {
  const prepareCopyExtraButton = useCallback((title, actionCallback) => {
    return (
      <ShowForRightAccessComponent>
        <Button
          type="default"
          className="common-button"
          onClick={actionCallback}
          title={title}
          icon={<CopyOutlined />}
        >
          {title}
        </Button>
      </ShowForRightAccessComponent>
    );
  }, []);

  const prepareExtraButton = useCallback((title, actionCallback) => {
    return (
      <ShowForRightAccessComponent>
        <Button
          type="primary"
          className="common-button"
          onClick={actionCallback}
          title={title}
          icon={<PlusOutlined />}
        >
          {title}
        </Button>
      </ShowForRightAccessComponent>
    );
  }, []);
  const { selectedTab, onTabChange } = useTabNavigation();
  const { extraButton, updateExtraButtons } =
    useExtraButtons(prepareExtraButton);
  const {
    showCopyPopUp,
    handleCancel,
    copyButton,
    getCopyTitle,
    getCopyContent,
    updateCopyButtons,
  } = useCopyModal(prepareCopyExtraButton);

  const tabItems = [
    {
      key: "6",
      url: "/product/targetattributes",
      label: (
        <Space>
          <ApartmentOutlined />
          Target Attributes
        </Space>
      ),
      access: [Role.ADMIN, Role.USER],
    },
    {
      key: "3",
      url: "/product/target",
      label: (
        <Space>
          <SwapOutlined />
          Targets
        </Space>
      ),
    },
    {
      label: (
        <Space>
          <AppstoreOutlined /> Builds
        </Space>
      ),
      key: "1",
      url: "/product/build",
    },
    {
      key: "2",
      url: "/product/release",
      label: (
        <Space>
          <CheckCircleOutlined />
          Releases
        </Space>
      ),
    },
    {
      key: "8",
      url: "/product/featureFlag",
      label: (
        <Space>
          <FlagOutlined />
          Features
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "9",
      url: "/product/featureRollout",
      label: (
        <Space>
          <RiseOutlined />
          Feature Rollouts
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "10",
      url: "/product/contentTemplates",
      label: (
        <Space>
          <NotificationOutlined /> Content Templates
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "11",
      url: "/product/contentRollouts",
      label: (
        <Space>
          <FundViewOutlined /> Content Rollouts
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "4",
      url: "/product/test",
      label: (
        <Space>
          <AimOutlined />
          Test
        </Space>
      ),
    },
    {
      key: "7",
      url: "/product/users",
      label: (
        <Space>
          <UsergroupAddOutlined />
          Users
        </Space>
      ),
      access: [Role.ADMIN],
    },
    {
      key: "12",
      url: "/product/files",
      label: (
        <Space>
          <FileSearchOutlined /> Files
        </Space>
      ),
      access: [Role.ADMIN],
    },
  ];

  React.useEffect(() => {
    if (selectedTab.action === "list") {
      updateCopyButtons(selectedTab.id);
      updateExtraButtons(selectedTab.id);
    }
  }, [selectedTab, updateCopyButtons, updateExtraButtons]);
  return (
    <>
      <React.Fragment>
        <Modal
          open={!!showCopyPopUp}
          title={getCopyTitle()}
          onCancel={handleCancel}
          footer={null}
        >
          {getCopyContent()}
        </Modal>
        <div>
          <ProductInfoTabLayout
            selectedTabId={selectedTab.id}
            tabs={tabItems}
            onTabChange={onTabChange}
            extraButton={selectedTab?.action === "list" ? extraButton : null}
            copyButton={selectedTab?.action === "list" ? copyButton : null}
          />
        </div>
        <Routes>
          <Route index path="/" element={<BuildRoute />} />
          <Route index path="/build/*" element={<BuildRoute />} />
          <Route index path="/release/*" element={<ReleaseRoute />} />
          <Route
            index
            path="/contentTemplates/*"
            element={<ContentTemplatesRoute />}
          />
          <Route
            index
            path="/contentRollouts/*"
            element={<ContentRolloutRoutes />}
          />
          <Route index path="/target/*" element={<TargetRoute />} />
          <Route index path="/test" element={<TestContainer />} />
          <Route index path="/details" element={<DetailsContainer />} />
          <Route
            index
            path="/targetattributes/*"
            element={<TargetAttributesRoute />}
          />
          <Route index path="/users/*" element={<UsersRoute />} />
          <Route index path="/featureFlag/*" element={<FeatureFlagRoute />} />
          <Route
            index
            path="/featureRollout/*"
            element={<FeatureRolloutRoute />}
          />
          <Route index path="/files/*" element={<FileRoute />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default ProductInfoRoutes;

