import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import { DefaultPagingValue } from "../../Constant/Common";
import { convertDataToQueryParam } from "../../Util/commonUtility";
import useFetchData from "../../Hooks/useFetchData";
import ListFileComponent from "../../Components/FileModule/listFileComponent";

const ListFileContainer = React.forwardRef(() => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [listFilterData, setListFilterData] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  const {
    data: Files,
    loading: showLoading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_FILE.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    ).replace(
      "#{productId}",
      productState?.selectedProduct?.id
    )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter change
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter reset
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <>
          <ListFileComponent
            Files={Files}
            listFilterData={listFilterData}
            paging={pagination}
            handlePageChange={handlePageChange}
            listFilterSubmit={listFilterSubmit}
            resetFilter={resetFilter}
          />
        </>
      )}
    </>
  );
});

export default ListFileContainer;

