import { useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useExtraButtons = (prepareExtraButton) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [extraButton, setExtraButton] = useState(null);

  const updateExtraButtons = useCallback(
    (tabId) => {
      const buttonConfig = {
        // Configuration object for extra buttons. Keys represent tab IDs, and values contain button details.
        1: { extra: "Add Build" },
        2: { extra: "Add Release" },
        3: { extra: "Add Target" },
        6: { extra: "Add Target Attribute" },
        7: { extra: "Add Product User" },
        8: { extra: "Add Feature" },
        9: { extra: "Add Feature Rollout" },
        10: { extra: "Add Content Template" },
        11: { extra: "Add Content Rollout" },
        12: { extra: "Add File" },
      };

      const config = buttonConfig[tabId];
      if (config) {
        setExtraButton(
          prepareExtraButton(config.extra, () =>
            navigate(`${location.pathname}/add`)
          )
        );
      } else {
        setExtraButton(null);
      }
    },
    [prepareExtraButton, navigate, location.pathname]
  );

  return { extraButton, updateExtraButtons };
};

