import React, { useEffect, useRef } from "react";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { inValidJson } from "../../Util/config";
import NotificationService from "./../../Services/notification";

const JsonEditor = ({
  jsonSchema,
  mode = "code",
  onChangeJSON = () => {},
  setCanSubmit,
}) => {
  const containerRef = useRef(null);
  const jsonEditorRef = useRef(null);

  useEffect(() => {
    const options = {
      onChange: () => {
        if (jsonEditorRef.current) {
          jsonEditorRef.current.validate().then((errors) => {
            if (errors && errors.length > 0) {
              setCanSubmit && setCanSubmit(inValidJson);
              NotificationService.error("Error", inValidJson);
            } else {
              if (mode === "view") {
                jsonEditorRef.current.expandAll();
              }
            }
          });
          const updatedJSON = jsonEditorRef.current?.get();
          onChangeJSON(updatedJSON);
        }
      },
      mode: mode,
      navigationBar: false,
    };
    if (!isNullOrEmpty(jsonSchema)) {
      jsonEditorRef.current = new JSONEditor(containerRef.current, options);
      jsonEditorRef.current.set(jsonSchema);
      mode === "view" && jsonEditorRef.current.expandAll();
    }
    return () => {
      if (jsonEditorRef.current) {
        jsonEditorRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonSchema, mode]);

  return <div className="jsoneditor-react-container" ref={containerRef} />;
};

export default JsonEditor;

