import React, { useEffect, useState } from "react";
import ListTargetProductComponent from "../../Components/ProductTarget/listTargetProductComponent";
import Urls from "../../Constant/Urls";
import { DefaultPagingValue } from "../../Constant/Common";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import {
  convertDataToQueryParam,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import Loader from "../../Components/Common/Loader";
import CommonTestMessage from "../../Components/Common/commonTestMessage";
// import { Form } from "antd";
import NotificationService from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";
const ListTragetContainer = React.forwardRef(() => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [listFilterData, setListFilterData] = useState({});
  const navigate = useNavigate();

  const [showTestMessage, setShowTestMessage] = useState({
    visible: false,
    message: undefined,
    title: undefined,
  });

  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  const {
    data: productTargets,
    loading: showLoading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_PRODUCT_TARGETS.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  // Update local pagination state when fetchedPagination changes
  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const deleteProductTarget = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_TARGET.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{targetId}", data.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success("Success", "Target deleted successfully.");
      setListFilterData({}); // Reset filter to reflect changes
    }
  };

  const testProductTargetById = async (data) => {
    const response = await httpClient.get(
      `${Urls.TENANT_GET_TARGET_TEST.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{targetId}", data.id)}`
    );
    if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
      if (response.data?.length > 0) {
        setShowTestMessage({
          visible: true,
          message: response?.data?.map((item) => item),
          title: "Target Hit",
        });
      }
    } else {
    }
  };

  const editProductTarget = async (data) => {
    navigate(`/product/target/edit/${data.id}`);
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter change
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter reset
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListTargetProductComponent
          productTargets={productTargets}
          listFilterData={listFilterData}
          paging={pagination}
          editProductTarget={(data) => editProductTarget(data)}
          deleteProductTarget={(data) => deleteProductTarget(data)}
          testProductTargetById={(data) => testProductTargetById(data)}
          handlePageChange={handlePageChange}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
        />
      )}
      {showTestMessage && (
        <CommonTestMessage
          title={showTestMessage.title}
          visible={showTestMessage.visible}
          testMessage={showTestMessage.message}
          closeTestMessage={() => {
            setShowTestMessage({ visible: false, message: null });
          }}
        />
      )}
    </>
  );
});
export default ListTragetContainer;

