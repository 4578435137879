import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const useTabNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState({ id: "1", action: "list" });

  const onTabChange = useCallback(
    (tabId) => {
      const routes = {
        1: "/product/build",
        2: "/product/release",
        3: "/product/target",
        4: "/product/test",
        5: "/product/details",
        6: "/product/targetattributes",
        7: "/product/users",
        8: "/product/featureFlag",
        9: "/product/featureRollout",
        10: "/product/contentTemplates",
        11: "/product/contentRollout",
        12: "/product/files",
      };
      if (routes[tabId]) {
        navigate(routes[tabId]);
        setSelectedTab({ id: tabId, action: "list" });
      }
    },
    [navigate]
  );

  useEffect(() => {
    const path = location.pathname.split("/");
    const tabMap = {
      build: "1",
      release: "2",
      target: "3",
      test: "4",
      details: "5",
      targetattributes: "6",
      users: "7",
      featureFlag: "8",
      featureRollout: "9",
      contentTemplates: "10",
      contentRollouts: "11",
      files: "12",
    };

    if (path[1] === "product" && path[2] in tabMap) {
      const tabId = tabMap[path[2]];
      const action = path[3] === "add" || path[3] === "edit" ? path[3] : "list";
      setSelectedTab({ id: tabId, action });
    }
  }, [location.pathname]);

  return { selectedTab, onTabChange };
};

