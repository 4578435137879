import React from "react";
import { Route, Routes } from "react-router-dom";
import FeatureFlagListScreen from "./featureFlagListScreen";
import FeatureFlagAddScreen from "./featureFlagAddScreen";
import Notfound from "../ErrorPages/notFound";
const FeatureFlagRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<FeatureFlagListScreen />} />
          <Route index path="/edit/:id" element={<FeatureFlagAddScreen />} />
          <Route index path="/add" element={<FeatureFlagAddScreen />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default FeatureFlagRoute;
