import { useEffect, useState } from "react";
import AddProductFeatureFlagComponent from "../../Components/FeatureFlag/addProductFeatureFlagComponent";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useSelector } from "react-redux";
import { isNullOrEmpty } from "../../Util/commonUtility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import NotificationService from "../../Services/notification";
import { inValidJson } from "../../Util/config";

const AddFeatureFlagContainer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [showLoading, setShowLoading] = useState(false);
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [addEditProductFeatureFlag, setAddEditProductFeatureFlag] = useState();
  const [canSubmit, setCanSubmit] = useState(true);

  useEffect(() => {
    const getProductFeatureFlagByFeatureFlagId = async (id) => {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_PRODUCT_FEATURE_FLAG_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{featureFlagId}", id)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        setAddEditProductFeatureFlag(response.data);
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    const getProductFeatureFlagForCopy = async (state) => {
      setShowLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_PRODUCT_FEATURE_FLAG_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{featureFlagId}", state.id)}`
      );
      if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
        console.log(response.data);
        setAddEditProductFeatureFlag({
          ...response.data,
          id: null,
          name: state.name,
          code: null,
        });
      } else {
        backToListPage();
      }
      setShowLoading(false);
    };
    if (id) {
      getProductFeatureFlagByFeatureFlagId(id);
    } else if (state) {
      getProductFeatureFlagForCopy(state);
    } else {
      setShowLoading(false);
    }
  }, [id, productState, authState, state]);

  const addProductFeatureFlagAPI = async (data) => {
    if (!canSubmit) {
      NotificationService.error(
        "Please resolve all the validation errors in generated form before submitting"
      );
      return; // Prevent submission as validation occurs in the generated form
    }
    if (canSubmit === inValidJson) {
      NotificationService.error("Invalid JSON");
      window.scrollTo(0, 0);
      return; // Prevent submission as validation occurs in the generated form
    }

    if (data.id) {
      setShowLoading(true);
      const response = await httpClient.put(
        `${Urls.TENANT_EDIT_PRODUCT_FEATURE_FLAG.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{featureFlagId}", data.id)}`,
        data
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success("Success", "FeatureFlag edit successfully");
      } else {
        setAddEditProductFeatureFlag(data);
        NotificationService.error("Error", "FeatureFlag not edit");
      }
      setShowLoading(false);
    } else {
      setShowLoading(true);
      const response = await httpClient.post(
        `${Urls.TENANT_ADD_PRODUCT_FEATURE_FLAG.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ).replace("#{productId}", productState?.selectedProduct?.id)}`,
        data
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success(
          "Success",
          "FeatureFlag Added successfully"
        );
      } else {
        setAddEditProductFeatureFlag(data);
      }
      setShowLoading(false);
    }
  };

  const backToListPage = () => {
    navigate("/product/featureFlag");
  };

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddProductFeatureFlagComponent
          onFinish={(data) => addProductFeatureFlagAPI(data)}
          editProductFeatureFlagData={addEditProductFeatureFlag}
          id={id}
          backToListPage={() => {
            backToListPage();
          }}
          setCanSubmit={setCanSubmit}
        />
      )}
    </>
  );
};
export default AddFeatureFlagContainer;

