import {
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Space } from "antd";
import { logo } from "../../Resource/images";
import { THEME_KEY, getLocalStorage } from "../../Util/commonUtility";
import darkLogo from "../../assets/DECO.png";

const LoginComponent = ({ onFinish, showLoading }) => {
  const handlePasswordVisibility = (visible) => {
    // Handle password visibility logic if needed
  };
  const isDarkMode = getLocalStorage(THEME_KEY) || false;

  const validatePassword = (_, value) => {
    if (!value || value.length < 8) {
      return Promise.reject("Password must be at least 8 characters");
    }

    // Check if the password contains at least one alphabet, one number, and one special character
    const regex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/;
    if (!regex.test(value)) {
      return Promise.reject(
        "Password must include at least one alphabet, one number, and one special character"
      );
    }

    return Promise.resolve();
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col className="login-card" span={6}>
        <Card
          title={
            <div
              style={{
                widows: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={isDarkMode ? darkLogo : logo} alt="Company Logo" />
            </div>
          }
        >
          <Form name="normal_login" className="login-form" onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
                placeholder="Password"
                onClick={() => handlePasswordVisibility(true)}
              />
            </Form.Item>
            <Form.Item className="text-center">
              <Space className="login-form-space">
                <Button
                  id="login"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={showLoading}
                  icon={showLoading ? <LoadingOutlined /> : ""}
                >
                  Log in
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginComponent;

