import {
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  theme,
  ColorPicker,
  Divider,
  Drawer,
  Space,
  DatePicker,
  Radio,
  Image,
} from "antd";
import React, { useEffect } from "react";
import { cyan, generate, green, presetPalettes, red } from "@ant-design/colors";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  controlTypeOptions,
  dataTypeOptions,
} from "../../../Constant/DropDown";
import {
  CONTROL_TYPE,
  DATA_TYPE,
  DateFormat,
  PlaceHolderUnderScopeValidationMessage,
  fallbackImage,
  maxNameCharacterLength,
} from "../../../Constant/Common";
import {
  DECIMAL_REGEX,
  INTEGER_REGEX,
  UNDER_SCORE_REGEX_REGEXP,
} from "../../../Constant/regex";
import dayjs from "dayjs";
import FileListPopUpContainer from "../../../Containers/ContentTemplates/FileList/fileListPopup";

const AddEditContentPlaceHolder = (props) => {
  const {
    setAllPlaceHolderData,
    placeHolderData,
    setPlaceHolderData,
    allPlaceHolderData,
    isEdit,
    open,
    setOpen,
  } = props;
  const [addContentPlaceHolderForm] = Form.useForm();
  const [selectedImageDefaultValue, setSelectedImageDefaultValue] =
    React.useState();
  const [selectedControlType, setSelectedControlType] = React.useState(null);
  const [selectedDataType, setSelectedDataType] = React.useState(null);
  const [dropdownData, setDropdownData] = React.useState([]);

  const { token } = theme.useToken();
  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map(([label, colors]) => ({
      label,
      colors,
    }));
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });
  const customPanelRender = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" className="content-placeholder-divider" />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  useEffect(() => {
    if (
      selectedImageDefaultValue &&
      selectedControlType === CONTROL_TYPE.IMAGE_UPLOADER
    ) {
      addContentPlaceHolderForm.setFieldValue(
        "defaultValue",
        selectedImageDefaultValue
      );
    }
  });

  const handleClose = () => {
    if (!isEdit) {
      setSelectedControlType(null);
      setSelectedDataType(null);
      setSelectedImageDefaultValue(null);
      addContentPlaceHolderForm.resetFields();
      setDropdownData([]);
    }
    setPlaceHolderData(null);
    setOpen(false);
  };

  const DefaultValueValidator = (_, value) => {
    const decimalPattern = DECIMAL_REGEX;
    const integerPattern = INTEGER_REGEX;

    if (
      selectedDataType === DATA_TYPE.INTEGER &&
      value &&
      !integerPattern.test(value)
    ) {
      return Promise.reject(new Error("Please enter a valid integer!"));
    }

    if (
      selectedDataType === DATA_TYPE.DECIMAL &&
      value &&
      !decimalPattern.test(value)
    ) {
      return Promise.reject(new Error("Please enter a valid decimal value!"));
    }

    return Promise.resolve();
  };
  const uniqueNameValidator = (_, value) => {
    if (!isEdit || (placeHolderData && placeHolderData.name !== value)) {
      const filterUniqueName = allPlaceHolderData?.filter(
        (obj) => obj.name === value
      );
      if (value && filterUniqueName?.length > 0) {
        return Promise.reject(new Error(`Name Already in use : ${value}!`));
      }
    }
    return Promise.resolve();
  };

  const UnderScoreValidator = () => {
    const value = addContentPlaceHolderForm.getFieldValue("name");
    if (value === undefined || value === null || value.trim() === "") {
      return Promise.resolve();
    }
    if (!UNDER_SCORE_REGEX_REGEXP.test(value)) {
      return Promise.reject(new Error(PlaceHolderUnderScopeValidationMessage));
    }
    return Promise.resolve();
  };

  const formatDataType = (values) => {
    let finalValue = values.dataType;
    if (!values.dataType) {
      finalValue = values.controlType;
    }

    return finalValue;
  };
  const formatDefaultValues = (values) => {
    let finalValue = values.defaultValue;

    if (selectedControlType === CONTROL_TYPE.COLOR_PICKER) {
      finalValue =
        typeof values.defaultValue === "string"
          ? values.defaultValue
          : values.defaultValue?.toHexString();
    }
    if (selectedControlType === CONTROL_TYPE.DATE) {
      finalValue = dayjs(values.defaultValue).format(DateFormat);
    }

    return finalValue;
  };

  const handleFinish = (values) => {
    const Data = {
      ...values,
      defaultValue: formatDefaultValues(values),
      dataType: formatDataType(values),
    };
    if (isEdit) {
      const filterData = allPlaceHolderData?.filter(
        (obj) => obj.name !== placeHolderData.name
      );
      setAllPlaceHolderData([...filterData, Data]);
    } else {
      setAllPlaceHolderData((prevData) => {
        return [...prevData, Data];
      });
    }
    handleClose();
  };

  const handleFinishFailed = (errorInfo) => {
    console.error(errorInfo);
  };
  const resetDefaultValueValidation = () => {
    addContentPlaceHolderForm.setFields([
      {
        name: "defaultValue",
        errors: [], // Clear errors
      },
    ]);
  };

  const handleDataTypeChange = (data) => {
    setSelectedDataType(data);
    resetDefaultValueValidation(); // Reset the validation when data type changes
    addContentPlaceHolderForm.setFieldValue("defaultValue", null); // Optionally reset the value
  };
  const handleControlTypeChange = (data) => {
    setSelectedControlType(data);
    resetDefaultValueValidation(); // Reset the validation when data type changes
    addContentPlaceHolderForm.setFieldValue("defaultValue", null); // Optionally reset the value
    addContentPlaceHolderForm.setFieldValue("dataType", null); // Optionally reset the value
  };

  useEffect(() => {
    const defaultValueFormat = (data) => {
      if (data.controlType === CONTROL_TYPE.DATE) {
        return dayjs(data.defaultValue);
      }
      return data.defaultValue;
    };
    if (isEdit && placeHolderData) {
      addContentPlaceHolderForm.setFieldsValue({
        ...placeHolderData,
        defaultValue: defaultValueFormat(placeHolderData),
      });
      setSelectedControlType(placeHolderData.controlType);
      setSelectedDataType(placeHolderData.dataType);
      setDropdownData(
        placeHolderData?.controlData?.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    } else {
      addContentPlaceHolderForm.resetFields();
      setDropdownData([]);
      setSelectedControlType(null);
      setSelectedDataType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, placeHolderData]);

  const handleInputChange = () => {
    const formValue = addContentPlaceHolderForm.getFieldValue("controlData");
    const controlDataValue = formValue
      ?.filter((item) => item && item.trim() !== "") // Exclude whitespace-only values
      .map((item) => {
        return {
          label: item.trim(),
          value: item.trim(),
        };
      });
    setDropdownData(controlDataValue);
  };
  const handleRemove = (remove, name) => {
    remove(name);
    handleInputChange();
    resetDefaultValueValidation(); // Reset the validation when data type changes
    addContentPlaceHolderForm.setFieldValue("defaultValue", null); // Optionally reset the value
  };
  const handleChangeNameInput = (name) => {
    addContentPlaceHolderForm.setFieldValue("name", name?.toUpperCase());
  };
  const handleChangeLabelInput = (label) => {
    addContentPlaceHolderForm.setFieldValue("label", label?.toUpperCase());
  };

  return (
    <>
      <Drawer
        width={450}
        closable={false}
        destroyOnClose
        title={<p>{`${isEdit ? "Edit" : "Add"} Content Placeholder`}</p>}
        placement="right"
        open={open}
        onClose={handleClose}
        extra={
          <Space>
            <Button className="view-button-cancel" onClick={handleClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <Form
          form={addContentPlaceHolderForm}
          className="common-form"
          id="add-AddEditContentPlaceHolder"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                id="name"
                rules={[
                  {
                    required: true,
                    message: "Please input name!",
                  },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                  {
                    validator: uniqueNameValidator,
                  },
                  {
                    validator: UnderScoreValidator,
                  },
                ]}
              >
                <Input
                  id="TokenName"
                  prefixCls="add-content-placeholder"
                  onChange={(e) => handleChangeNameInput(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Label"
                name="label"
                id="label"
                rules={[
                  {
                    required: true,
                    message: "Please input label!",
                  },
                ]}
              >
                <Input
                  id="TokenLabel"
                  prefixCls="add-content-placeholder"
                  onChange={(e) => handleChangeLabelInput(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Control Type"
                name="controlType"
                id="controlType"
                rules={[
                  {
                    required: true,
                    message: "Please select control type!",
                  },
                ]}
              >
                <Select
                  id="controlType"
                  prefixCls="add-content-placeholder-select"
                  onChange={handleControlTypeChange}
                >
                  {controlTypeOptions?.map((type, index) => (
                    <Select.Option key={type.value} value={type.value}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {selectedControlType === CONTROL_TYPE.TEXT && (
            <>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Data Type"
                    name="dataType"
                    id="dataType"
                    rules={[
                      {
                        required: true,
                        message: "Please select data type!",
                      },
                    ]}
                  >
                    <Select
                      id="dataType"
                      prefixCls="add-content-placeholder-select"
                      onChange={handleDataTypeChange}
                    >
                      {dataTypeOptions?.map((type, index) => (
                        <Select.Option key={type.value} value={type.value}>
                          {type.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Default Value"
                    name="defaultValue"
                    id="defaultValue"
                    rules={[
                      {
                        required: true,
                        message: "Please input default value!",
                      },
                      selectedDataType === DATA_TYPE.URL
                        ? {
                            type: "url",
                            message: "This field must be a valid URL.",
                          }
                        : {
                            validator: DefaultValueValidator,
                          },
                    ]}
                  >
                    <Input
                      id="defaultValue"
                      prefixCls="add-content-placeholder"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {selectedControlType === CONTROL_TYPE.COLOR_PICKER && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Default Value"
                  name="defaultValue"
                  id="defaultValue"
                  rules={[
                    {
                      required: true,
                      message: "Please select default color picker value!",
                    },
                  ]}
                >
                  <ColorPicker
                    showText
                    presets={presets}
                    panelRender={customPanelRender}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedControlType === CONTROL_TYPE.DATE && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Default Value"
                  name="defaultValue"
                  id="defaultValue"
                  rules={[
                    {
                      required: true,
                      message: "Please select default date value!",
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedControlType === CONTROL_TYPE.DROPDOWN && (
            <>
              <Row>
                <Col span={24}>
                  <Form.Item label="Dropdown Data">
                    <Form.List
                      name="controlData"
                      rules={[
                        {
                          required: true,
                          validator: async (_, data) => {
                            const controlTypeData =
                              addContentPlaceHolderForm.getFieldValue(
                                "controlType"
                              );
                            const condition =
                              controlTypeData === CONTROL_TYPE.DROPDOWN &&
                              data?.length > 0;
                            if (!condition) {
                              return Promise.reject(
                                new Error(
                                  "Please add at least one default value for dropdown data!"
                                )
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} align="baseline">
                              <Form.Item
                                {...restField}
                                name={name}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter value!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter Value"
                                  onChange={handleInputChange}
                                />
                              </Form.Item>
                              <Button
                                type="primary"
                                className="content-placeholder-delete-button"
                                onClick={() => handleRemove(remove, name)}
                                block
                              >
                                Delete
                              </Button>
                            </Space>
                          ))}
                          <Form.Item prefixCls="add-dropdown-placeholder">
                            <Button
                              type="dashed"
                              className={
                                errors?.length > 0 ? "button-danger" : ""
                              }
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add
                            </Button>
                          </Form.Item>
                          <Form.ErrorList errors={errors} />
                        </>
                      )}
                    </Form.List>
                  </Form.Item>
                </Col>
              </Row>

              {dropdownData?.length > 0 && (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Default Value"
                      name="defaultValue"
                      id="defaultValue"
                      rules={[
                        {
                          required: true,
                          message: "Please select default value!",
                        },
                      ]}
                    >
                      <Select
                        id="controlType"
                        prefixCls="add-content-placeholder-select"
                      >
                        {dropdownData?.map((type, index) => (
                          <Select.Option key={index} value={type.value}>
                            {type.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
          {selectedControlType === CONTROL_TYPE.IMAGE_UPLOADER && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Default Value"
                  name="defaultValue"
                  id="defaultValue"
                  rules={[
                    {
                      required: true,
                      message: "Please upload image/image URL!",
                    },
                  ]}
                >
                  <FileListPopUpContainer
                    setSelectedImageDefaultValue={setSelectedImageDefaultValue}
                    isEdit={isEdit}
                    placeHolderData={placeHolderData}
                    selectedImageDefaultValue={selectedImageDefaultValue}
                  />
                </Form.Item>
              </Col>
              {selectedImageDefaultValue && (
                <Col span={12}>
                  <div className="uploader-placeholder-container">
                    <Image
                      src={selectedImageDefaultValue}
                      width={100}
                      height={100}
                      fallback={fallbackImage}
                      alt="CDN Preview"
                    />
                  </div>
                </Col>
              )}
            </Row>
          )}
          {selectedControlType === CONTROL_TYPE.BOOLEAN && (
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Default Value"
                  name="defaultValue"
                  id="defaultValue"
                  rules={[
                    {
                      required: true,
                      message: "Please select default boolean value!",
                    },
                  ]}
                >
                  <Radio.Group optionType="button" buttonStyle="solid">
                    <Radio value={"true"}>True</Radio>
                    <Radio value={"false"}>False</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item
            id="content-placeholder-button"
            prefixCls="submit-btn-placeholder"
          >
            <Button
              className="submit-btn"
              id="submitAddEditContentPlaceHolder"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddEditContentPlaceHolder;

