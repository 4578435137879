import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import Loader from "../Common/Loader";
const { Option } = Select;

const AddProductUserComponent = (props) => {
  const {
    allUser,
    onFinish,
    onFinishFailed,
    backToListPage,
    editProductUsersData,
    allProductEnvironmentsData,
  } = props;

  const [initialFormValues, setInitialFormValues] = useState(false);
  const [addProductUserForm] = Form.useForm();
  const [switchEnabled, setSwitchEnabled] = useState({});

  useEffect(() => {
    if (allProductEnvironmentsData) {
      const combinedArray = editProductUsersData
        ? [...editProductUsersData]
        : [];
        
      for (const obj of allProductEnvironmentsData.result) {
        if (!combinedArray.some((item) => item.environmentId === obj.id)) {
          obj.environmentId = obj.id;
          obj.environmentName = obj.name;
          combinedArray.push(obj);
        }
      }

      const initialEnvironmentValues = combinedArray.map((env) => ({
        ...env,
        environment: env.isFullAccess ? env.isFullAccess : !!env.productId,
      }));

      setInitialFormValues({
        accountId: editProductUsersData?.[0]?.accountId,
        environment: initialEnvironmentValues,
      });

      addProductUserForm.setFieldsValue({
        accountId: editProductUsersData?.[0]?.accountId,
        environment: initialEnvironmentValues,
      });

      // Set initial state for enabling/disabling switches
      const switchState = {};
      initialEnvironmentValues.forEach((env, index) => {
        switchState[index] = env.environment;
      });
      setSwitchEnabled(switchState);
    }
  }, [allProductEnvironmentsData, editProductUsersData, addProductUserForm]);

  const onEnvironmentCheck = (event, index) => {
    const checked = event.target.checked;
    const environmentValues = addProductUserForm.getFieldValue("environment");

    environmentValues[index].environment = checked;
    addProductUserForm.setFieldsValue({ environment: environmentValues });

    setSwitchEnabled((prevState) => ({
      ...prevState,
      [index]: checked,
    }));
  };

  const handleFilterOption = (input, option) => {
    const children = option?.props?.children;
    if (Array.isArray(children)) {
      const childrenString = children.join('');
      return childrenString.toLowerCase().includes(input?.toLowerCase());
    }
    if (typeof children === 'string') {
      return children.toLowerCase().includes(input?.toLowerCase());
    }
    return false;
  };
  

  return (
    <>
      {initialFormValues ? (
        <Form
          name="add-product-user common-form"
          wrapperCol={{ span: 10 }}
          form={addProductUserForm}
          colon={false}
          onFinish={(data) => onFinish(data, editProductUsersData ? "Edit" : "Add")}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="horizontal"
          initialValues={initialFormValues}
        >
          {!editProductUsersData ? (
            <Form.Item
              label="User"
              name="accountId"
              rules={[{ required: true, message: "Please select user" }]}
            >
              <Select
                id="selectEditProductUser"
                showSearch
                placeholder="Please search/select user"
                filterOption={handleFilterOption}
                disabled={!!editProductUsersData}
              >
                {allUser?.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.name} ({user.email})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <div className="user">
              <p style={{ fontSize: "14px" }}>User :</p>
              <div
                style={{
                  width: "67%",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <p style={{ fontSize: "14px" }}>
                  {editProductUsersData[0]?.name} ({editProductUsersData[0]?.email})
                </p>
              </div>
              <Form.Item
                name="accountId"
                hidden
                rules={[{ required: true, message: "Please select user" }]}
              >
                <Select
                  id="selectAddProductUser"
                  showSearch
                  placeholder="Please search/select user"
                  filterOption={handleFilterOption}
                  disabled={!!editProductUsersData}
                >
                  {allUser?.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.name} ({user.email})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          
          <Form.List name="environment">
            {(fields) => (
              <>
                {initialFormValues?.environment.map((environment, index) => (
                  <Row key={environment.environmentId} justify="space-between" className="user-line">
                    <Col lg={4} md={4} sm={6} xs={24}>
                      <Space size="large">
                        <Form.Item
                          name={[index, "environmentId"]}
                          initialValue={environment.environmentId}
                          noStyle
                          hidden
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[index, "environment"]}
                          valuePropName="checked"
                        >
                          <Checkbox
                            onChange={(event) => onEnvironmentCheck(event, index)}
                          />
                        </Form.Item>
                        <Form.Item label={`${environment.environmentName}`}></Form.Item>
                      </Space>
                    </Col>
                    <Col lg={17} md={17} sm={6} xs={24}>
                      <Space>
                        <Form.Item
                          name={[index, "isFullAccess"]}
                          valuePropName="checked"
                        >
                          <Switch disabled={!switchEnabled[index]} />
                        </Form.Item>
                        <Form.Item label="Full Access"></Form.Item>
                      </Space>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item>
            <Space>
              <Button
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={backToListPage}
              >
                Back
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddProductUserComponent;
