import React from "react";
import JsonForm from "../Common/JsonForm";
import JsonEditor from "../Common/jsonEditor";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const FlagEditor = ({
  editorSchema,
  formSchema,
  onChangeJSON = () => {},
  mode = "code",
  formValues,
  setFormValues,
  setCanSubmit,
  formContainerRef,
  formEditorRef,
  validationErrors,
  setValidationErrors,
}) => {
  const helpItems = [
    {
      key: "1",
      label: "How to Use the JSON Editor?",
      children: (
        <div>
          <ol>
            <li>Enter your JSON schema in the editor on the left side.</li>
            <li>
              Once you add a valid schema, a form will be generated on the right
              side.
            </li>
            <li>
              Modify the schema, and the form will automatically update based on
              the new schema.
            </li>
            <li>
              Fill out the form, and the values will be saved as per the schema.
            </li>
          </ol>
          <p>
            Ensure your schema is correct by following proper JSON schema
            standards. The generated form will allow you to input values that
            conform to the provided schema.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="feature-flag-content">
      <Collapse
        prefixCls="feature-collapse"
        items={helpItems}
        ghost
        expandIconPosition="left"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      />
      <div className="feature-flag-editor-container">
        <JsonEditor
          jsonSchema={editorSchema}
          onChangeJSON={onChangeJSON}
          setCanSubmit={setCanSubmit}
          mode={mode}
        />
        <JsonForm
          jsonSchema={formSchema}
          formValues={formValues}
          setFormValues={setFormValues}
          setCanSubmit={setCanSubmit}
          formContainerRef={formContainerRef}
          formEditorRef={formEditorRef}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
        />
      </div>
    </div>
  );
};
export default FlagEditor;

