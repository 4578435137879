import React from "react";
import { Route, Routes } from "react-router-dom";
import UsersListScreen from "./usersListScreen";
import UsersAddScreen from "./usersAddScreen";
import Notfound from "../ErrorPages/notFound";

const UsersRoute = React.forwardRef((props, ref) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<UsersListScreen ref={ref} />} />
          <Route index path="/add" element={<UsersAddScreen />} />
          <Route index path="/edit/:id" element={<UsersAddScreen />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </React.Fragment>
    </>
  );
});

export default UsersRoute;
