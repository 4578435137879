import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Tag,
} from "antd";
import { useEffect, useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  debounceForSearchApiCall,
  isJson,
  isNullOrEmpty,
  scrollToErrorOnForm,
} from "../../Util/commonUtility";
import { SearchProductFeatures } from "../../Services/searchService";
import { useSelector } from "react-redux";
import Loader from "../Common/Loader";
import RolloutEditor from "./rolloutEditor";
import { maxNameCharacterLength } from "../../Constant/Common";

const { Sider } = Layout;
const AddProductFeatureRolloutComponent = (props) => {
  const {
    id,
    onFinish,
    backToListPage,
    editProductFeatureRolloutData,
    targetOperators,
    productTargets,
    setCanSubmit,
    showDuplicationPopUp,
    setShowDuplicationPopUp,
    duplicateErrorMessage,
  } = props;
  const [addProductFeatureRolloutForm] = Form.useForm();
  const [collapsed, setCollapsed] = useState(false);
  const [featureArray, setFeatureArray] = useState([]);
  const [showDropDownLoading, setShowDropDownLoading] = useState(false);
  const [selectedTargetIdsArray, setSelectedTargetIdsArray] = useState([]);
  const [editorSchema, setEditorSchema] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [formValues, setFormValues] = useState({});
  const formContainerRef = useRef(null);
  const formEditorRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const handleFinish = (values) => {
    if (validationErrors.length > 0) {
      scrollToErrorOnForm(validationErrors.slice(-1)[0].path, formEditorRef);
    }
    onFinish(values);
  };

  const handleFinishFailed = (errorInfo) => {
    if (validationErrors.length > 0) {
      scrollToErrorOnForm(validationErrors.slice(-1)[0].path, formEditorRef);
    }
  };

  const onSearchFeature = async (data) => {
    let queryParam = data
      ? `&SearchText=${data}&isEnabled=true`
      : "&isEnabled=true";
    setShowDropDownLoading(true);
    const newResult = await SearchProductFeatures(
      queryParam,
      authState,
      productState
    );
    setFeatureArray(newResult);
    setShowDropDownLoading(false);
  };

  useEffect(() => {
    if (formValues && !isNullOrEmpty(formValues)) {
      setEditorSchema(formValues);
      addProductFeatureRolloutForm.setFieldValue("config", formValues);
    }
  }, [addProductFeatureRolloutForm, formValues]);

  const onSelectFeature = (featureId) => {
    const feature = featureArray.find((obj) => obj.id === featureId);
    addProductFeatureRolloutForm.setFieldValue("feature", feature);
    if (feature && isJson(feature.defaultConfig)) {
      try {
        let parsedJson = JSON.parse(feature.defaultConfig);
        setFormValues(parsedJson);
        setEditorSchema(parsedJson);
        let jsonSchema = JSON.parse(feature.schemaConfig);
        setFormSchema(jsonSchema);
        addProductFeatureRolloutForm.setFieldsValue({
          config: parsedJson,
        });
      } catch (error) {
        console.error("Failed to parse JSON:", error);
      }
    }
  };

  const onSelectTarget = (targetId, name) => {
    setSelectedTargetIdsArray((prev) => {
      const updatedArray = [...prev];
      const existingIndex = updatedArray.findIndex((item) => item.id === name);
      if (existingIndex !== -1) {
        updatedArray[existingIndex].value = targetId;
      } else {
        updatedArray.push({ id: name, value: targetId });
      }
      return updatedArray;
    });
  };

  const onRemoveTarget = (targetId) => {
    setSelectedTargetIdsArray((prev) =>
      prev.filter((target) => target.value !== targetId)
    );
  };

  useEffect(() => {
    if (!editProductFeatureRolloutData) return;

    const setFeatureConfig = () => {
      if (id) {
        const config = editProductFeatureRolloutData.config;
        const parsedConfig =
          typeof config === "object" ? config : JSON.parse(config);
        setFormValues(parsedConfig);
        setEditorSchema(parsedConfig);
      } else {
        let featureConfig;
        featureConfig = editProductFeatureRolloutData.config;
        setFormValues(featureConfig);
        setEditorSchema(featureConfig);
      }
    };
    const setFeatureSchemaConfig = () => {
      const feature = featureArray.find(
        (obj) => obj.id === editProductFeatureRolloutData.featureId
      );
      const parsedSchema = feature ? JSON.parse(feature.schemaConfig) : {};
      setFormSchema(parsedSchema);
    };

    setFeatureConfig();
    setFeatureSchemaConfig();
    addProductFeatureRolloutForm.setFieldsValue(editProductFeatureRolloutData);
  }, [
    id,
    editProductFeatureRolloutData,
    featureArray,
    addProductFeatureRolloutForm,
    duplicateErrorMessage,
  ]);

  useEffect(() => {
    onSearchFeature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOk = () => {
    const formattedData = {
      ...editProductFeatureRolloutData,
      AllowForceInsert: true,
    };
    onFinish(formattedData);
    setShowDuplicationPopUp(false);
  };
  const handleCancel = () => {
    setShowDuplicationPopUp(false);
  };

  return (
    <>
      <Form
        form={addProductFeatureRolloutForm}
        initialValues={editProductFeatureRolloutData}
        className="common-form"
        name="add-feature-rollout"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Modal
          open={showDuplicationPopUp}
          title="Warning: Duplicate Target Detected"
          okText="Proceed and Save"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          )}
        >
          <p className="text-gray-700 mb-4">
            A target with the same identifier already exists in the feature
            rollout. Do you want to proceed with forcefully inserting this
            feature rollout? Please note that this action may overwrite the
            existing target.
          </p>
          <span className="text-sm font-bold mb-2">
            {duplicateErrorMessage}
          </span>
        </Modal>
        <Row>
          <Col>
            {editProductFeatureRolloutData && (
              <Space className="" direction="horizontal">
                {editProductFeatureRolloutData.updatedBy && (
                  <>
                    Updated By :
                    <Tag>{editProductFeatureRolloutData.updatedBy}</Tag>
                  </>
                )}
                {editProductFeatureRolloutData.updatedAt && (
                  <>
                    Updated Date :
                    <Tag>
                      {new Date(
                        editProductFeatureRolloutData.updatedAt
                      ).toDateString()}
                    </Tag>
                  </>
                )}
              </Space>
            )}
          </Col>
        </Row>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={toggleCollapsed}
            width={300}
            style={{ background: "#fff" }}
          >
            <Card
              className="deco-card-padding"
              style={{ minHeight: "100vh" }}
              title="Feature Rollout"
              extra={
                <>
                  <Button type="ghost" onClick={toggleCollapsed}>
                    {collapsed ? <RightOutlined /> : <LeftOutlined />}
                  </Button>
                </>
              }
            >
              {collapsed ? "Expand to add/edit feature rollout data" : null}
              <Form.Item hidden={true} name="id">
                <Input />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                hidden={collapsed ? true : false}
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                  {
                    max: maxNameCharacterLength,
                    message: "Input cannot exceed 50 characters!",
                  },
                ]}
              >
                <Input id="releaseName" className="build-rollout-max-width" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                hidden={collapsed ? true : false}
                rules={[
                  {
                    required: true,
                    message: "Please input your description!",
                  },
                  { max: 200, message: "Code must be at least 200 characters" },
                ]}
              >
                <TextArea
                  id="releaseDescription"
                  className="build-rollout-max-width"
                />
              </Form.Item>
              <Form.Item
                label="Feature"
                name="featureId"
                hidden={collapsed ? true : false}
                rules={[
                  {
                    required: true,
                    message: "Please input code!",
                  },
                ]}
              >
                <Select
                  id="featureId"
                  className="build-rollout-max-width"
                  onChange={(data) => onSelectFeature(data)}
                  onSearch={debounceForSearchApiCall(onSearchFeature)}
                  notFoundContent={showDropDownLoading ? <Loader /> : <Empty />}
                >
                  {featureArray?.map((build, index) => (
                    <Select.Option key={build.id} value={build.id}>
                      {build.name} {`( ${build.code} )`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Target Criteria"
                hidden={collapsed ? true : false}
                required
              >
                <Form.List
                  name={"targets"}
                  rules={[
                    {
                      required: true,
                      validator: async (_, data) => {
                        let equationGroup =
                          addProductFeatureRolloutForm.getFieldValue(
                            "targetCriteria"
                          )?.equationGroup || [];
                        let condition =
                          equationGroup.length > 0 || (data && data.length > 0);

                        if (!condition) {
                          return Promise.reject(
                            new Error(
                              "Please add at least one target attribute for feature"
                            )
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space align="baseline" key={key}>
                          <Space.Compact block>
                            <Form.Item
                              {...restField}
                              name={[name, "targetId"]}
                              className=""
                              rules={[
                                {
                                  required: true,
                                  message: "Please select target",
                                },
                              ]}
                            >
                              <Select
                                className="select-target-min-width"
                                onChange={(data) => {
                                  onSelectTarget(data, name);
                                }}
                              >
                                {productTargets?.map((build, index) => {
                                  return (
                                    <Select.Option
                                      key={build.id}
                                      value={build.id}
                                      disabled={selectedTargetIdsArray.some(
                                        (a) => a.value === build.id
                                      )}
                                    >
                                      {build.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "targetOperator"]}
                              className=""
                              initialValue={
                                targetOperators && targetOperators?.length > 0
                                  ? targetOperators[0].name
                                  : ""
                              }
                            >
                              <Select
                                options={targetOperators?.map(
                                  (build, index) => ({
                                    label: build.label,
                                    value: build.name,
                                  })
                                )}
                                className="select-min-width"
                              ></Select>
                            </Form.Item>

                            <Button
                              type="primary"
                              onClick={async () => {
                                await onRemoveTarget(
                                  addProductFeatureRolloutForm.getFieldValue([
                                    "targets",
                                    name,
                                    "targetId",
                                  ])
                                );

                                remove(name);
                              }}
                              icon={<DeleteOutlined />}
                            ></Button>
                          </Space.Compact>
                        </Space>
                      ))}

                      <Form.Item
                        name={"targetCriteria"}
                        prefixCls="target-criteria"
                      >
                        <Button
                          id="addTargetInRelease"
                          type="dashed"
                          onClick={() => add()}
                          block
                          size="small"
                          icon={<PlusOutlined />}
                          className={errors?.length > 0 ? "button-danger" : ""}
                        >
                          Add Target
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <Form.Item
                label="Enabled?"
                name={"isEnabled"}
                hidden={collapsed ? true : false}
                valuePropName="checked"
              >
                <Switch id="releaseSwitch" />
              </Form.Item>
            </Card>
          </Sider>
          <Card className="deco-card-padding">
            <Form.Item name={"config"} label="Config" hidden={true}>
              <Input></Input>
            </Form.Item>
            <RolloutEditor
              editorSchema={editorSchema}
              formSchema={formSchema}
              formValues={formValues}
              setFormValues={setFormValues}
              setCanSubmit={setCanSubmit}
              formContainerRef={formContainerRef}
              formEditorRef={formEditorRef}
              validationErrors={validationErrors}
              setValidationErrors={setValidationErrors}
              mode="view"
            />
            <Form.Item>
              <Space className="feature-flag-btn-space">
                <Button
                  id="backToListingPage"
                  type="default"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    backToListPage();
                  }}
                >
                  Back
                </Button>
                <Button
                  className="submit-btn"
                  id="submitRelease"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Card>
        </Layout>
      </Form>
    </>
  );
};
export default AddProductFeatureRolloutComponent;

