import React from "react";
import { Route, Routes } from "react-router-dom";
import BuildListScreen from "./buildListScreen";
import BuildAddScreen from "./buildAddScreen";
import Notfound from "../ErrorPages/notFound";
const BuildRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<BuildListScreen />} />
          <Route index path="/edit/:id" element={<BuildAddScreen />} />
          <Route index path="/add" element={<BuildAddScreen />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default BuildRoute;
