/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import {
  DefaultPagingValue,
  DefaultPagingValueForSearchableDropdown,
} from "../../Constant/Common";
import {
  fromKeyValueArrayToObject,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationService from "../../Services/notification";
import { useDispatch } from "react-redux";
import { setAllTargetOperatorsDetails } from "../../Store/Reducers/Slices/LookUp/lookupSlice";
import { internalRoutes } from "../../Constant/internalRoutes";
import AddContentRolloutsComponent from "../../Components/ContentRollouts/addContentRolloutsComponent";

const AddContentRolloutContainer = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [loading, setLoading] = useState(false);
  const [productBuild, setProductBuild] = useState([]);
  const [productTargets, setProductTargets] = useState([]);
  const [targetOperators, setTargetOperators] = useState([]);
  const [addEditContentRollout, setAddEditContentRollout] = useState();
  const { allTargetOperators } = useSelector((state) => state.lookup);
  const [productAttributes, setProductAttributes] = useState(null);
  const [allContentTemplates, setAllContentTemplates] = useState([]);

  const [paging, setPaging] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE,
    pageIndex: DefaultPagingValue.PAGE_INDEX,
    totalPages: DefaultPagingValue.TOTAL_PAGES,
  });

  useEffect(() => {
    const fetchData = async () => {
      const editContentRolloutById = async (id) => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_CONTENT_ROLLOUT_BY_ID.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace(
                "#{environmentId}",
                productState?.selectedEnvironment?.id
              )
              .replace("#{rolloutId}", id)}
              `
          );
          if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
            setAddEditContentRollout(response.data);
          } else {
            backToListPage();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      const editContentRolloutByIdForCopy = async (state) => {
        setLoading(true);
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_CONTENT_ROLLOUT_BY_ID.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace("#{environmentId}", state.environmentId)
              .replace("#{rolloutId}", state.id)}
              `
          );
          if (!isNullOrEmpty(response) && isSuccessStatus(response?.status)) {
            const responseData = response?.data;
            const Data = {
              name: state.name,
              buildId: null,
              contentTemplateId: responseData?.contentTemplateId,
              templateType: responseData?.templateType,
              templateData: responseData?.templateData,
              templateSchema: responseData?.templateSchema,
              templatePlaceHolder: responseData?.templatePlaceHolder,
              isEnable: responseData?.isEnable,
              targets: null,
              frequency: responseData?.frequency,
              showAt: responseData?.showAt,
              showTime: responseData?.showTime,
              days: responseData?.days,
              startDate: responseData?.startDate,
              endDate: responseData?.endDate,
              end: responseData?.end,
              numberOfShow: responseData?.numberOfShow,
            };
            setAddEditContentRollout(Data);
          } else {
            backToListPage();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      const fetchAllProductBuild = async () => {
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_ALL_PRODUCT_BUILDS.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace(
                "#{environmentId}",
                productState?.selectedEnvironment?.id
              )}?PageNumber=${
              DefaultPagingValueForSearchableDropdown.PAGE_INDEX
            }&PageSize=${
              DefaultPagingValueForSearchableDropdown.PAGE_SIZE
            }&isEnabled=true`
          );

          if (
            isSuccessStatus(response?.status) &&
            !isNullOrEmpty(response?.data)
          ) {
            setProductBuild(response?.data?.result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      const fetchAllProductTarget = async () => {
        try {
          const response = await httpClient.get(
            `${Urls.TENANT_GET_ALL_PRODUCT_TARGETS.replace(
              "#{tenantId}",
              authState?.accountData?.tenantId
            )
              .replace("#{productId}", productState?.selectedProduct?.id)
              .replace(
                "#{environmentId}",
                productState?.selectedEnvironment?.id
              )}?PageNumber=${
              DefaultPagingValueForSearchableDropdown.PAGE_INDEX
            }&PageSize=${DefaultPagingValueForSearchableDropdown.PAGE_SIZE}`
          );

          if (
            isSuccessStatus(response?.status) &&
            !isNullOrEmpty(response?.data)
          ) {
            setProductTargets(
              response?.data?.result?.filter((target) => target.isEnabled)
            );
          }
        } catch (error) {
          console.error(error);
        }
      };
      if (id) await editContentRolloutById(id);
      if (state) await editContentRolloutByIdForCopy(state);
      await fetchAllProductTarget();
      await fetchAllProductBuild();
    };

    fetchData();
  }, [id, productState, authState]);

  const fetchAllTargetOperators = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(
        `${Urls.LOOKUP_TARGET_TARGETOPERATORS}`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        dispatch(
          setAllTargetOperatorsDetails({
            allTargetOperators: response?.data?.map((item) => ({
              name: item,
              label: item,
            })),
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addContentRolloutApi = async (data) => {
    try {
      setLoading(true);
      if (data.updateBuildMeta) {
        data.updateBuildMeta = fromKeyValueArrayToObject(data.updateBuildMeta);
      }
      if (data.id) {
        const response = await httpClient.put(
          Urls.TENANT_UPDATE_CONTENT_ROLLOUT.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          )
            .replace("#{productId}", productState?.selectedProduct?.id)
            .replace("#{environmentId}", productState?.selectedEnvironment?.id)
            .replace("#{rolloutId}", data.id),
          data
        );
        if (isSuccessStatus(response?.status)) {
          NotificationService.success(
            "Success",
            "Content rollout edited successfully"
          );
          backToListPage();
        } else {
          setAddEditContentRollout(data);
        }
      } else {
        const response = await httpClient.post(
          Urls.TENANT_ADD_CONTENT_ROLLOUT.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          )
            .replace("#{productId}", productState?.selectedProduct?.id)
            .replace("#{environmentId}", productState?.selectedEnvironment?.id),
          data
        );
        if (isSuccessStatus(response?.status)) {
          NotificationService.success(
            "Success",
            "Content rollout added successfully"
          );
          backToListPage();
        } else {
          const finalData = {
            ...data,
            templateData: data?.templateData,
            templatePlaceHolder: data?.templatePlaceholder,
          };
          setAddEditContentRollout(finalData);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllContentTemplates = async () => {
    try {
      const response = await httpClient.get(
        `${Urls.TENANT_GET_ALL_CONTENT_TEMPLATES.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace(
            "#{environmentId}",
            productState?.selectedEnvironment?.id
          )}?PageSize=1000&isEnable=true`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        setAllContentTemplates(response.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const backToListPage = () => {
    navigate(internalRoutes.contentRollout);
  };

  const fetchAllTargetAttributes = async () => {
    setLoading(true);
    const response = await httpClient.get(
      `${Urls.TENANT_GET_ALL_PRODUCT_TARGET_ATTRIBUTES.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      ).replace(
        "#{productId}",
        productState?.selectedProduct?.id
      )}?PageNumber=${paging.pageIndex}&PageSize=${paging.pageSize}`
    );
    if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
      setProductAttributes(
        response.data?.result?.map((item) => {
          return {
            value: item.name,
            label: item.name,
            type: item.type,
          };
        })
      );
      if (response.data?.pagingStrategy) {
        setPaging({
          pageIndex: response.data?.pagingStrategy?.currentPage,
          pageSize: response.data?.pagingStrategy?.pageSize,
          totalPages: response.data?.pagingStrategy?.totalPages,
        });
      }
      if (response.data?.result?.length <= 0) {
        setPaging({
          ...paging,
          pageIndex: DefaultPagingValue.PAGE_INDEX,
        });
      }
    } else {
    }
    setLoading(false);
  };

  const contentTemplatePreview = async (contentData) => {
    try {
      const { tenantId } = authState?.accountData || {};
      const { id: productId } = productState?.selectedProduct || {};
      if (!tenantId || !productId) {
        throw new Error("Missing tenantId or productId.");
      }
      const previewUrl = Urls.CONTENT_TEMPLATE_PREVIEW.replace(
        "#{tenantId}",
        tenantId
      ).replace("#{productId}", productId);
      const response = await httpClient.post(previewUrl, { contentData });
      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        const { contentKey } = response?.data;
        if (contentKey) {
          const xamlUrl = Urls.XAML_TEMPLATE_PREVIEW.replace(
            "#{tenantId}",
            tenantId
          )
            .replace("#{productId}", productId)
            .replace("#{contentKey}", contentKey);
          const anchor = document.createElement("a");
          anchor.href = xamlUrl;
          anchor.target = "_blank";
          anchor.click();
        } else {
          throw new Error("Content key is missing in the response.");
        }
      } else {
        throw new Error("Failed to retrieve a valid response from the server.");
      }
    } catch (error) {
      console.error("Error previewing the product:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchAllTargetAttributes();
    fetchAllContentTemplates();
  }, [paging.pageIndex, paging.pageSize]);

  useEffect(() => {
    if (allTargetOperators) {
      setTargetOperators(allTargetOperators);
    } else {
      fetchAllTargetOperators();
    }
  }, [allTargetOperators]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AddContentRolloutsComponent
          allContentTemplates={allContentTemplates}
          editContentRolloutData={addEditContentRollout}
          targetOperators={targetOperators}
          productTargets={productTargets}
          targetAttributeDropdownData={productAttributes}
          productBuildArray={productBuild}
          onPreviewXaml={(data) => contentTemplatePreview(data)}
          onFinish={(data) => addContentRolloutApi(data)}
          backToListPage={backToListPage}
        />
      )}
    </>
  );
});

export default AddContentRolloutContainer;

