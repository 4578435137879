import React, { useEffect, useState } from "react";
import { Button, Col, Form, Radio, Row, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { fieldGenerator, responseHandle } from "./fieldGenerator";
import { useNavigate } from "react-router-dom";

dayjs.locale("en");

const AddTestComponent = ({
  targetAttributeDropdownData,
  testDistributionData,
  testFormSubmitHandler,
  onClickClearButton,
  isDataAvailable,
}) => {
  const [formProductTest] = Form.useForm();
  const [testData, setTestData] = useState([]);
  const [typeArray, setTypeArray] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const navigate = useNavigate();

  const updateTypeArray = async (index, value) => {
    const formData = await formProductTest.getFieldsValue();
    const currentValue = formData?.data?.[index]?.value;
    if (!currentValue) {
      formProductTest.validateFields();
    }
    const updatedTypeArray = [...typeArray];
    updatedTypeArray[index] = value;
    setTypeArray(updatedTypeArray.filter(Boolean));
  };

  const onClearButton = () => {
    formProductTest.resetFields();
    setSelectedKeys([]);
    onClickClearButton();
  };

  const handleSubmit = (values) => {
    testFormSubmitHandler(values);
  };

  const handleSelectChange = (index, value, option) => {
    updateTypeArray(index, option.type);
    const newSelectedKeys = [...selectedKeys];
    newSelectedKeys[index] = value;
    setSelectedKeys(newSelectedKeys);
    const currentData = formProductTest.getFieldValue("data") || [];
    currentData[index] = { key: value, value: null };
    formProductTest.setFieldsValue({ data: currentData });
  };

  const isOptionDisabled = (optionKey) => {
    return selectedKeys.includes(optionKey);
  };

  useEffect(() => {
    if (targetAttributeDropdownData) {
      setTestData(targetAttributeDropdownData);
      formProductTest.resetFields();
      setSelectedKeys([]);
    }
  }, [targetAttributeDropdownData]);

  return (
    <div className="bread-Crumb-Container">
      <Form
        form={formProductTest}
        onFinish={handleSubmit}
        initialValues={{
          testType: "build",
        }}
      >
        {/* Form.Item for Test Data */}
        <Form.Item label="Test Type" name="testType">
          <Radio.Group>
            <Radio value={"build"} defaultChecked={true}>
              Build
            </Radio>
            <Radio value={"feature"}>Feature</Radio>
            <Radio value={"content"}>Content</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Test Data">
          <Form.List
            label="Test Data"
            name={"data"}
            rules={[
              {
                validator: async (_, data) => {
                  if (!data || data.length < 1) {
                    return Promise.reject(
                      new Error(
                        "Please add at least one target attribute for test"
                      )
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row key={key} className="test">
                    <Col>
                      <Space align="baseline">
                        {/* Select Key */}
                        <Form.Item
                          {...restField}
                          name={[name, "key"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select target attribute",
                            },
                          ]}
                        >
                          <Select
                            className="test-page-select-width"
                            showSearch
                            placeholder="Key"
                            options={testData.map((option) => ({
                              ...option,
                              disabled: isOptionDisabled(option.value),
                            }))}
                            onChange={(value, option) =>
                              handleSelectChange(index, value, option)
                            }
                          />
                        </Form.Item>

                        {/* Generate Field based on Key Type */}
                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          initialValue={""}
                          validateTrigger="onChange"
                          getValueFromEvent={
                            typeArray[index] === "Date"
                              ? (e) => e?.format("YYYY-MM-DD")
                              : undefined
                          }
                          getValueProps={
                            typeArray[index] === "Date"
                              ? (e) => ({ value: e && dayjs(e) })
                              : undefined
                          }
                        >
                          {fieldGenerator(typeArray[index], formProductTest)}
                        </Form.Item>

                        <MinusCircleOutlined
                          id="removeFieldTest"
                          onClick={() => {
                            remove(name);
                            const newSelectedKeys = [...selectedKeys];
                            newSelectedKeys.splice(index, 1);
                            setSelectedKeys(newSelectedKeys);
                            const keyValue =
                              formProductTest.getFieldValue("key");
                            updateTypeArray(index, keyValue);
                          }}
                        />
                      </Space>
                    </Col>
                  </Row>
                ))}

                {/* Add Test Data Button */}
                <Form.Item>
                  <Button
                    id="addTest"
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Test Data
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>

        {/* Clear and Submit Buttons */}
        <Form.Item>
          <Space>
            <Button type="default" htmlType="button" onClick={onClearButton}>
              Clear
            </Button>
            <Button className="submit-btn" type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>

        {/* Display Test Distribution Data */}
        {isDataAvailable &&
          responseHandle(
            testDistributionData?.submittedData?.testType,
            testDistributionData?.responseData,
            navigate
          )}
      </Form>
    </div>
  );
};

export default AddTestComponent;
