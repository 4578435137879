import { Button, DatePicker, Form, Input, Space } from "antd";
import Loader from "../Common/Loader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";

import {
  DOB_FORMAT_FORM,
  MASKED_PHONE_NUMBER,
  PHONE_NUMBER_REGEX,
} from "../../Constant/Common";
import dayjs from "dayjs";
import { formatPhoneNumber } from "../../Util/commonUtility";
// // dayjs.locale("en");
const ProfileForm = (props) => {
  const {
    onFinishFailed,
    onFinish,
    formObject,
    initialValuesObject,
    backToListPage,
  } = props;
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <>
      {initialValuesObject ? (
        <Form
          className="common-form"
          form={formObject}
          initialValues={{
            ...initialValuesObject,
            userName: initialValuesObject.name,
            phoneNumber:formatPhoneNumber(initialValuesObject.phone),
            birthDate: dayjs(initialValuesObject.birthDate),
          }}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // autoComplete="off"
        >
          <Form.Item name="email" label="E-mail">
            <Input id="profileEmail" disabled={true} bordered={false} />
          </Form.Item>

          <Form.Item
            name="userName"
            label="Username"
            tooltip="What do you want others to call you?"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input id="profileUserName" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your Phone Number!",
              },
              {
                pattern: PHONE_NUMBER_REGEX,
                message: "Must be a valid phone number",
              },
            ]}
          >
            
            <MaskedInput prefixCls="mask-input" id="profilePhoneNumber" mask={MASKED_PHONE_NUMBER} />
      
          </Form.Item>
          {/* <Form.Item
            name="birthDate"
            label="BirthDate"
            rules={[
              {
                required: true,
                message: "Please input your birth date!",
              },
            ]}
          >
            <DatePicker id="profileBirthdate" disabledDate={disabledDate} />
          </Form.Item> */}
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button
                id="backToProfile"
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  backToListPage();
                }}
              >
                Back
              </Button>
              <Button className="submit-btn" id="submitProfile" type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default ProfileForm;
