import { Divider, Input, Popconfirm, Select, Space, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TotalReocordsInListingComponent from "../Common/totalRecordsInListing";
import { useSelector } from "react-redux";
import CommonFilterForm from "../Common/commonFilterForm";

const ListProductFeatureRolloutComponent = (props) => {
  const {
    productFeatureRollouts,
    editProductFeatureRollout,
    featureRolloutFilterSubmit,
    resetFilter,
    handlePageChange,
    featureRolloutFilterData,
    paging,
    deleteProductFeatureRollout,
  } = props;

  const isFullAccess = useSelector(
    (state) => state.product.selectedEnvironment?.isFullAccess
  );

  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
    {
      name: "description",
      component: (
        <Input className="list-filter-common-style" placeholder="Description" />
      ),
    },
    {
      name: "featureName",
      component: (
        <Input
          className="list-filter-common-style"
          placeholder="Feature Name"
        />
      ),
    },
    {
      name: "isEnabled",
      component: (
        <Select
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enabled?"
        >
          <Select.Option value={true}>Yes</Select.Option>
          <Select.Option value={false}>No</Select.Option>
        </Select>
      ),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Feature Name",
      dataIndex: "featureName",
      key: "featureName",
      // render: (code) => {
      //   return <Tag className="common-tag-color">{code}</Tag>;
      // },
    },
    {
      title: "Enable",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (isEnabled) => (isEnabled ? "Yes" : "NO"),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space>
            <Divider type="vertical"></Divider>
            <ShowForRightAccessComponent>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editProductFeatureRollout(record);
                }}
              />
            </ShowForRightAccessComponent>
            <Divider type="vertical"></Divider>
            <Popconfirm
              title="Are you sure to delete this feature rollout ?"
              onConfirm={() => {
                deleteProductFeatureRollout(record);
              }}
            >
              <DeleteOutlined title="Delete" />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={featureRolloutFilterSubmit}
        onReset={resetFilter}
        initialValues={featureRolloutFilterData}
      />
      <Divider />
      <Table
        className="featureRollout-listing"
        dataSource={productFeatureRollouts?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalReocordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          totalRecords: paging.totalRecords,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns.filter((column) => {
          if (column.title === "Action" && !isFullAccess) {
            return false;
          }
          return true;
        })}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};
export default ListProductFeatureRolloutComponent;

