import {
  Button,
  Col,
  ColorPicker,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  theme,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useCallback, useEffect, useState } from "react";
import FileListPopUpContainer from "./../../../Containers/ContentTemplates/FileList/fileListPopup";
import {
  ContentTypes,
  CONTROL_TYPE,
  DATA_TYPE,
  fallbackImage,
} from "../../../Constant/Common";
import { cyan, generate, green, presetPalettes, red } from "@ant-design/colors";
import { DECIMAL_REGEX, INTEGER_REGEX } from "./../../../Constant/regex";
import dayjs from "dayjs";
import {
  removeWhitespaceFromXAML,
  replaceContentWithPlaceholders,
  validateCodeSyntax,
} from "../../../Util/commonUtility";
import EditorPreview from "../ModalComponent/ModalComponent";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

const DefaultIntegerValidator = (_, value) => {
  if (value && !INTEGER_REGEX.test(value)) {
    return Promise.reject(new Error("Please enter a valid integer!"));
  }
  return Promise.resolve();
};

const DefaultDecimalValidator = (_, value) => {
  if (value && !DECIMAL_REGEX.test(value)) {
    return Promise.reject(new Error("Please enter a valid decimal value!"));
  }
  return Promise.resolve();
};

const formRules = (record) => {
  const rules = [];

  if (record.dataType === DATA_TYPE.URL) {
    rules.push({ type: "url", message: "This field must be a valid URL." });
  }

  if (record.validator) {
    rules.push({
      validator: record.validator,
      message: record.message,
    });
  }

  return rules;
};

const FormItemWrapper = ({ lang, record, children }) => (
  <Row className="mb-2">
    <Col span={18}>
      <Form.Item
        label={record.label}
        key={`${lang.code}_${record.name}`}
        name={`${lang.code}_${record.name}`}
        rules={formRules(record)}
        className="custom-form-item"
      >
        {children}
      </Form.Item>
    </Col>
  </Row>
);

const DynamicForm = (props) => {
  const {
    templateData,
    activeTab,
    setActiveTab,
    onPreviewXaml,
    currentLanguagePlaceholderData,
    formContentRollout,
    allFormValues,
    setAllFormValues,
  } = props;

  const { id } = useParams();
  const { selectedTemplateType, templateSchema } = templateData;
  const [isEditorCodeValid, setIsEditorCodeValid] = useState(false);
  const [isEditorCodeSyntaxError, setIsEditorCodeSyntaxError] = useState(false);
  const { token } = theme.useToken();
  const [showCopyConfirm, setShowCopyConfirm] = useState({
    showPopConfirm: false,
    selectedLanguage: null,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [replacedContent, setReplacedContent] = useState(false);
  const isTemplateSchemaNonEmpty = templateSchema?.length > 0;
  const isPreviewVisible =
    isEditorCodeSyntaxError && isTemplateSchemaNonEmpty && isEditorCodeValid;

  const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map(([label, colors]) => ({
      label,
      colors,
    }));
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });
  const customPanelRender = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" className="content-placeholder-divider" />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  const renderRecord = (record) => {
    if (record.dataType === DATA_TYPE.DECIMAL) {
      return { ...record, validator: DefaultDecimalValidator };
    }
    if (record.dataType === DATA_TYPE.INTEGER) {
      return { ...record, validator: DefaultIntegerValidator };
    }
    return record;
  };

  const renderFormItems = useCallback(
    (lang) => {
      return templateData.templatePlaceholder.map((field) => {
        const currentLanguageFormValues = allFormValues
          ? allFormValues[lang.code]
          : {};
        const currentImageDefaultValue =
          (activeTab &&
            currentLanguageFormValues[`${activeTab}_${field.name}`]) ||
          fallbackImage;

        switch (field.controlType) {
          case CONTROL_TYPE.TEXT:
            return (
              <FormItemWrapper lang={lang} record={renderRecord(field)}>
                <Input />
              </FormItemWrapper>
            );

          case CONTROL_TYPE.COLOR_PICKER:
            return (
              <FormItemWrapper lang={lang} record={field}>
                <ColorPicker
                  showText
                  presets={presets}
                  panelRender={customPanelRender}
                  size="small"
                  className="custom-color-picker"
                />
              </FormItemWrapper>
            );

          case CONTROL_TYPE.DATE:
            return (
              <FormItemWrapper lang={lang} record={field}>
                <DatePicker />
              </FormItemWrapper>
            );

          case CONTROL_TYPE.DROPDOWN:
            return (
              <FormItemWrapper lang={lang} record={field}>
                <Select>
                  {field.controlData?.map((type) => (
                    <Select.Option key={`${field.name}_${type}`} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </FormItemWrapper>
            );

          case CONTROL_TYPE.IMAGE_UPLOADER:
            return (
              <Row className="items-center" key={`${lang.code}_${field.name}`}>
                <Col span={12}>
                  <FormItemWrapper lang={lang} record={field}>
                    <FileListPopUpContainer
                      formContentRollout={formContentRollout}
                      currentImageDefaultValue={currentImageDefaultValue}
                      allFormValues={allFormValues}
                      setAllFormValues={setAllFormValues}
                      placeHolderData={field}
                      usedForRollout
                      isEdit={id}
                      language={lang}
                    />
                  </FormItemWrapper>
                </Col>
                <Col span={12}>
                  <Image
                    width={100}
                    src={currentImageDefaultValue}
                    fallback={fallbackImage}
                    className="rounded-md"
                    key={currentImageDefaultValue}
                  />
                </Col>
              </Row>
            );

          case CONTROL_TYPE.BOOLEAN:
            return (
              <FormItemWrapper lang={lang} record={field}>
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  size="small"
                >
                  <Radio value={"true"}>True</Radio>
                  <Radio value={"false"}>False</Radio>
                </Radio.Group>
              </FormItemWrapper>
            );

          default:
            return null;
        }
      });
    },
    [
      templateData.templatePlaceholder,
      allFormValues,
      activeTab,
      presets,
      formContentRollout,
      setAllFormValues,
      id,
    ]
  );

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (templateSchema || currentLanguagePlaceholderData) {
      const { replacedContent, isValid } = replaceContentWithPlaceholders(
        templateSchema,
        currentLanguagePlaceholderData
      );
      const { isValid: isCodeValid } = validateCodeSyntax(
        replacedContent,
        selectedTemplateType
      );
      setReplacedContent(replacedContent);
      setIsEditorCodeValid(isValid);
      setIsEditorCodeSyntaxError(isCodeValid);
    }
  }, [templateSchema, currentLanguagePlaceholderData, selectedTemplateType]);

  const handleCopyFormChange = (language) => {
    setShowCopyConfirm({
      ...showCopyConfirm,
      showPopConfirm: true,
      selectedLanguage: language,
    });
  };

  const handleOpen = () => {
    if (selectedTemplateType === ContentTypes.XAML) {
      onPreviewXaml(removeWhitespaceFromXAML(replacedContent));
    } else {
      setIsModalVisible(true);
    }
  };
  const handleClose = () => setIsModalVisible(false);

  const copyFromValueFormat = (record, defaultValue) => {
    if (record.controlType === CONTROL_TYPE.DATE) {
      return dayjs(defaultValue);
    }
    return defaultValue;
  };

  const handleOk = () => {
    const selectedLanguageFormValues =
      allFormValues[showCopyConfirm.selectedLanguage];
    const currentLanguageFormValues = allFormValues[activeTab];

    const copyFormFinalData = templateData.templatePlaceholder.reduce(
      (acc, placeholder) => {
        const sourceValue =
          placeholder?.dataType === DATA_TYPE.STRING
            ? currentLanguageFormValues[`${activeTab}_${placeholder.name}`]
            : selectedLanguageFormValues[
                `${showCopyConfirm.selectedLanguage}_${placeholder.name}`
              ];
        const formattedValue = copyFromValueFormat(placeholder, sourceValue);
        return {
          ...acc,
          [`${activeTab}_${placeholder.name}`]: formattedValue,
        };
      },
      {}
    );

    formContentRollout.setFieldsValue(copyFormFinalData);
    setAllFormValues((prevValues) => ({
      ...prevValues,
      [activeTab]: copyFormFinalData,
    }));
    setShowCopyConfirm({
      ...showCopyConfirm,
      showPopConfirm: false,
      selectedLanguage: null,
    });
  };

  const handleCancel = () => {
    setShowCopyConfirm({
      ...showCopyConfirm,
      showPopConfirm: false,
      selectedLanguage: null,
    });
  };
  const filterLanguages = templateData.languages?.filter(
    (obj) => obj.code !== activeTab
  );

  const copiedFromLanguage = templateData?.languages?.find(
    (obj) => obj.code === showCopyConfirm.selectedLanguage
  )?.name;
  const toBeCopiedLanguage = templateData?.languages?.find(
    (obj) => obj.code === activeTab
  )?.name;

  const copyFromDropdown = (
    <Space>
      {showCopyConfirm.showPopConfirm && (
        <Modal
          title="Confirm Data Copy!!"
          open={showCopyConfirm.showPopConfirm}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Continue"
        >
          <p>
            Are you sure you want to copy {copiedFromLanguage} language form
            data into {toBeCopiedLanguage} language form data?
          </p>
        </Modal>
      )}

      {filterLanguages?.length > 0 && (
        <Select
          prefixCls="copy-from-select"
          id="copyFrom"
          placeholder="Copy From"
          onChange={handleCopyFormChange}
          value={showCopyConfirm.selectedLanguage}
        >
          {filterLanguages?.map((type) => (
            <Select.Option key={type.name} value={type.code}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      )}
      <Button disabled={!isPreviewVisible} onClick={handleOpen} type="primary">
        Preview
      </Button>
    </Space>
  );
  return (
    <>
      {/* Preview Modal */}
      <EditorPreview
        visible={isModalVisible}
        onClose={handleClose}
        content={replacedContent}
        placeholder={currentLanguagePlaceholderData}
        language={selectedTemplateType}
      />

      {/* Language Tabs */}
      <Tabs
        key={activeTab}
        activeKey={activeTab}
        onChange={handleTabChange}
        tabBarExtraContent={copyFromDropdown}
      >
        {templateData.languages.map((lang) => (
          <TabPane tab={<span>{lang.name}</span>} key={lang.code}>
            {renderFormItems(lang)}
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default DynamicForm;

