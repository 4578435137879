import hljs from "highlight.js";
import xml from "highlight.js/lib/languages/xml";
import json from "highlight.js/lib/languages/json";
import { FileExtension } from "../Constant/Common";
import {
  ChromeOutlined,
  DesktopOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import WebIcon from "./../assets/webicon.png";
hljs.registerLanguage("xml", xml); // Covers XAML and HTML
hljs.registerLanguage("json", json);

export const isEmpty = (value) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty("length") && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

const dashboardPath = { path: "/", name: "Dashboard" };
const productPath = { path: "/product/", name: "Product" };
const settingsPath = { path: "/settings/profile", name: "Settings" };
const tenantUserPath = { path: "/tenant-users", name: "Tenant User" };
const productTargetAttributes = {
  path: "",
  name: "",
};
const productUsers = {
  path: "/product/users",
  name: "Users",
};
const notificationTemplates = {
  path: "/product/notificationTemplates",
  name: "",
};
const notificationRollouts = {
  path: "/product/notificationRollouts",
  name: "",
};
const addPath = { path: "", name: "" };
const listTargetAttributes = { path: "", name: "" };
const listBuilds = { path: "/product/build", name: "" };
const listReleases = { path: "/product/release", name: "" };
const listTargets = { path: "/product/target", name: "" };
const listFeatureFlags = { path: "/product/featureFlag", name: "" };
const listFeatureRollouts = {
  path: "/product/featureRollout",
  name: "",
};
const listNotificationTempalates = {
  path: "",
  name: "Notification Templates",
};
const listNotificationRollouts = {
  path: "",
  name: "Notification Rollouts",
};
const profileDetail = { path: "/settings/profile", name: "Profile" };
const editPath = { path: "", name: "" };

export const breadCrumbsPathForPages = {
  dashboard: [dashboardPath],
  product: [dashboardPath, { path: "", name: "All Products" }],
  addproduct: [dashboardPath, productPath, { path: "", name: "" }],
  editproduct: [dashboardPath, productPath, { path: "", name: "" }],
  tenantUser: [dashboardPath, { path: "", name: "Tenant User" }],
  addTenantUser: [dashboardPath, tenantUserPath, { path: "", name: "" }],
  editTenantUser: [dashboardPath, tenantUserPath, { path: "", name: "" }],
  settings: [dashboardPath, { path: "", name: "" }],
  productDetails: [dashboardPath, { path: "", name: "" }],
  productTargetAttributes: [dashboardPath, productPath, listTargetAttributes],
  addProductTargetAttributes: [
    dashboardPath,
    productPath,
    productTargetAttributes,
    addPath,
  ],
  editProductTargetAttributes: [
    dashboardPath,
    productPath,
    productTargetAttributes,
    editPath,
  ],
  productUsers: [dashboardPath, productPath, { path: "", name: "" }],
  notificationTemplates: [
    dashboardPath,
    productPath,
    listNotificationTempalates,
  ],
  addNotificationTemplates: [
    dashboardPath,
    productPath,
    notificationTemplates,
    addPath,
  ],
  editNotificationTemplates: [
    dashboardPath,
    productPath,
    notificationTemplates,
    editPath,
  ],
  notificationRollouts: [dashboardPath, productPath, listNotificationRollouts],
  addNotificationRollouts: [
    dashboardPath,
    productPath,
    notificationRollouts,
    addPath,
  ],
  editNotificationRollouts: [
    dashboardPath,
    productPath,
    notificationRollouts,
    editPath,
  ],
  addProductUsers: [dashboardPath, productPath, productUsers, addPath],
  editProductUsers: [dashboardPath, productPath, productUsers, editPath],
  builds: [dashboardPath, productPath, { path: "", name: "" }],
  releases: [dashboardPath, productPath, { path: "", name: "" }],
  tests: [dashboardPath, productPath, { path: "", name: "" }],
  targets: [dashboardPath, productPath, { path: "", name: "" }],
  addBuilds: [dashboardPath, productPath, listBuilds, addPath],
  editBuilds: [dashboardPath, productPath, listBuilds, editPath],
  addReleases: [dashboardPath, productPath, listReleases, addPath],
  editReleases: [dashboardPath, productPath, listReleases, editPath],
  addTargets: [dashboardPath, productPath, listTargets, addPath],
  editTargets: [dashboardPath, productPath, listTargets, editPath],
  editprofile: [dashboardPath, settingsPath, profileDetail, editPath],
  profile: [dashboardPath, settingsPath, { path: "", name: "Profile" }],
  changepassword: [
    dashboardPath,
    settingsPath,
    { path: "", name: "Change Password" },
  ],
  productUser: [{ path: "", name: "Product User List" }],
  featureFlags: [dashboardPath, productPath, { path: "", name: "" }],
  addFeatureFlags: [dashboardPath, productPath, listFeatureFlags, addPath],
  editFeatureFlags: [dashboardPath, productPath, listFeatureFlags, editPath],
  featureRollouts: [dashboardPath, productPath, { path: "", name: "" }],
  addFeatureRollouts: [
    dashboardPath,
    productPath,
    listFeatureRollouts,
    addPath,
  ],
  editFeatureRollouts: [
    dashboardPath,
    productPath,
    listFeatureRollouts,
    editPath,
  ],
};
// export const breadCrumbsPathForPages = {
//   dashboard: [{ path: "", name: "Dashboard" }],
//   product: [
//     { path: "/", name: "Dashboard" },
//     { path: "", name: "Product" },
//   ],
//   addproduct: [
//     { path: "/", name: "Dashboard" },
//     { path: "/product", name: "Product" },
//     { path: "", name: "Add" },
//   ],
//   editproduct: [
//     { path: "/", name: "Dashboard" },
//     { path: "/product", name: "Product" },
//     { path: "", name: "Edit" },
//   ],
//   tenantUser: [
//     { path: "/", name: "Dashboard" },
//     { path: "", name: "Tenant User" },
//   ],
//   addTenantUser: [
//     { path: "/", name: "Dashboard" },
//     { path: "/tenant-users", name: "Tenant User" },
//     { path: "", name: "Add" },
//   ],
//   editTenantUser: [
//     { path: "/", name: "Dashboard" },
//     { path: "/tenant-users", name: "Tenant User" },
//     { path: "", name: "Edit" },
//   ],
//   settings: [
//     { path: "/", name: "Dashboard" },
//     { path: "", name: "Settings" },
//   ],
//   productDetails: [
//     { path: "/", name: "Dashboard" },
//     { path: "/product", name: "Product" },
//     { path: "", name: "Details" },
//   ],
//   productUser: [{ path: "", name: "Product User List" }],
// };
// this function will return true if value is empty,undefine,length zero, object null other wise false
export const isNullOrEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" && value.trim() === "") {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  if (typeof value === "object" && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

export const isEmptyData = (value) => {
  return value?.length > 0 ? value : false;
};

export const isJson = (jsonstring) => {
  try {
    JSON.parse(jsonstring);
    return true;
  } catch (error) {
    return false;
  }
};

export const colorCodeBasedOnEnvironemnt = (env) => {
  switch (env) {
    case "production":
      return "#f5222d";
    case "qa":
      return "#5cdbd3";
    case "development":
      return "#ffc069";
    case "staging":
      return "#bae637";
    default:
      return "#69b1ff";
  }
};
export const colorCodeBasedOnRole = (env) => {
  switch (env) {
    case "Admin":
      return "#f5222d";
    case "User":
      return "#bae637";
    case "CICDUSER":
      return "#69b1ff";
    default:
      return "#69b1ff";
  }
};

export const getLocalStorageAccountInfo = () => {
  const localAccount = localStorage.getItem("account");
  if (isJson(localAccount)) {
    return JSON.parse(localAccount);
  } else {
    return undefined;
  }
};
export const setLocalStorageAccountInfo = (data) => {
  localStorage.setItem("account", JSON.stringify(data));
  localStorage.setItem("accessToken", data?.accessToken);
};

export const removeLocalStorageAccountInfo = () => {
  localStorage.removeItem("account");
  localStorage.removeItem("accessToken");
};
export const debounceForSearchApiCall = (func, delay = 1000) => {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};

export const fromObjectToKeyValueArray = (input) => {
  return (
    input &&
    Object.keys(input).map((key) => ({
      key,
      value: input[key],
    }))
  );
};

export const fromKeyValueArrayToObject = (dataArray) => {
  const reversedObject = {};
  dataArray &&
    dataArray.length > 0 &&
    dataArray.forEach((item) => {
      reversedObject[item.key] = item.value;
    });
  return reversedObject;
};
const transformComparisonOperator = (inputString) => {
  if (["GreaterThanEqualsTo", "LessThanEqualsTo"].includes(inputString)) {
    return inputString.replace(/(Than)(EqualsTo)/g, "ThanOr$2");
  } else {
    return inputString;
  }
};
export const operatorArrayToKeyValueObjectWithRegex = (inputArray) => {
  const resultArray = inputArray.map((item) => ({
    name: item,
    label: transformComparisonOperator(item)
      .replace(/([A-Z])/g, " $1")
      .trim(),
  }));

  return resultArray;
};
export const CommaSeperatedStringToArray = (commaSeparatedString) => {
  if (isJson(commaSeparatedString)) {
    return JSON.parse(commaSeparatedString);
  }
};

export const ArrayToCommaSeperatedString = (array) => {
  return JSON.stringify(array);
};
export const handleKeyDownAllowOnlyNumber = (e) => {
  // Get the key code of the pressed key
  const keyCode = e.keyCode || e.which;

  // Allow backspace, minus sign for negative numbers, and numpad enter
  if (
    keyCode === 8 || // Backspace
    (e.key === "-" && e.target.selectionStart === 0)
  ) {
    return;
  }

  // Allow numeric keys (0-9) from the main keyboard and numeric keypad
  if (
    (keyCode < 48 || keyCode > 57) && // Main keyboard numbers
    (keyCode < 96 || keyCode > 105) // Numeric keypad numbers
  ) {
    e.preventDefault();
  }
};
export const hasInvalidNested = (data) => {
  if (!data || !Array.isArray(data.equationGroup)) {
    return false;
  }

  return data.equationGroup.some(
    (a) => a.isValid === false || hasInvalidNested(a)
  );
};

export const isValidIPAddress = (value) => {
  // Regular expression for IPv4 address pattern
  const ipPattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  return ipPattern.test(value);
};
export const isValidVersion = (version) => {
  // Regular expression for the version format "P.Q", "P.Q.R", "P.Q.R.S"
  const versionRegex = /^\d+\.\d+(\.\d+(\.\d+)?)?$/;
  return versionRegex.test(version);
};
export const isValidDateFormat = (dateString) => {
  const dateFormatPattern = /^\d{4}-(0\d|1[0-2])-(0\d|1\d|2\d|3[0-1])$/;

  return dateFormatPattern.test(dateString);
};
export const checkValueOfEquIsValidOrNot = (operator, equation) => {
  switch (equation.operator) {
    case "Empty":
    case "NotEmpty":
      return true;
    case "In":
    case "NotIn":
      if (Array.isArray(equation.value) && !isNullOrEmpty(equation.value)) {
        const everyField = equation.value.every((eq) => {
          return checkBasedOnDataType(operator, eq) && !isNullOrEmpty(eq);
        });
        return everyField;
      }
      return false;
    case "FromTo":
      if (Array.isArray(equation.value) && equation.value.length === 2) {
        const everyField = equation.value.every((eq) => {
          return checkBasedOnDataType(operator, eq) && !isNullOrEmpty(eq);
        });
        return everyField;
      }
      return false;
    case "EqualsTo":
    case "NotEqualsTo":
    case "Contains":
    case "NotContains":
    case "StartWith":
    case "EndWith":
    case "LessThan":
    case "GreaterThanEqualsTo":
    case "LessThanEqualsTo":
    case "GreaterThan":
      if (!Array.isArray(equation.value)) {
        return checkBasedOnDataType(operator, equation.value);
      }
      return false;
    default:
      return false;
  }
};
export const checkBasedOnDataType = (
  operator,
  value,
  rangeArrayValue = undefined
) => {
  switch (operator) {
    case "String":
      return !isNullOrEmpty(value);
    case "Number":
      return !isNaN(value) && !isNullOrEmpty(value);
    case "Boolean":
      return typeof value === "boolean" && !isNullOrEmpty(value);
    case "Date":
      return isValidDateFormat(value) && !isNullOrEmpty(value);
    case "Version":
      if (rangeArrayValue) {
        return validateVersionRange(rangeArrayValue);
      }
      return isValidVersion(value) && !isNullOrEmpty(value);
    default:
      return false;
  }
};

export const validateVersionRange = (array) => {
  const from = array[0];
  const to = array[1];
  if (!isValidVersion(from) || !isValidVersion(to)) {
    return false; // Invalid version format
  }

  const fromParts = from.split(".").map(Number);
  const toParts = to.split(".").map(Number);

  // Compare the version parts
  for (let i = 0; i < Math.max(fromParts.length, toParts.length); i++) {
    const fromPart = fromParts[i] || 0;
    const toPart = toParts[i] || 0;
    if (fromPart < toPart) {
      return true; // Valid version range
    } else if (fromPart > toPart) {
      return false; // Invalid version range
    }
  }

  return true; // Both versions are equal
};
export const convertDataToQueryParam = (data) => {
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key]
  );
  const queryString = new URLSearchParams(data).toString();
  return queryString;
};

export const generateDataFromSchema = (schema) => {
  if (!schema || typeof schema !== "object") return {};
  const data = {};

  const generateDefaultValue = (schema) => {
    switch (schema.type) {
      case "string":
        return "";
      case "number":
        return 0;
      case "integer":
        return 0;
      case "boolean":
        return false;
      case "array":
        if (schema.items && schema.items.type === "object") {
          return [generateDataFromSchema(schema.items)];
        }
        return [];
      case "object":
        return generateDataFromSchema(schema);
      default:
        return null;
    }
  };

  if (schema.properties) {
    Object.keys(schema.properties).forEach((key) => {
      data[key] = generateDefaultValue(schema.properties[key]);
    });
  }

  return data;
};

export const addArrayDefaultValueToSchema = (schema) => {
  if (schema.type === "array" && !schema.default) {
    if (schema.items?.type === "object") {
      schema.default = [{}];
    } else if (schema.items?.type === "string") {
      schema.default = [""];
    } else if (schema.items?.type === "number") {
      schema.default = [0];
    }
  }

  if (schema.type === "object" && schema.properties) {
    Object.keys(schema.properties).forEach((key) => {
      schema.properties[key] = addArrayDefaultValueToSchema(
        schema.properties[key]
      );
    });
  }

  return schema;
};

export const setEditDataToSchema = (schema, editData) => {
  if (!schema || typeof schema !== "object") return {};

  const setDefaultIfMatchingKey = (schemaProperty, editData, schemaKey) => {
    if (editData[schemaKey] !== undefined) {
      schemaProperty.default = editData[schemaKey];
    }
  };

  if (schema.properties) {
    Object.keys(schema.properties).forEach((key) => {
      setDefaultIfMatchingKey(schema.properties[key], editData, key);
    });
  }

  return schema;
};

export const THEME_KEY = "themeMode";
// utils/localStorage.js

/**
 * Set a value in localStorage
 * @param {string} key - The key to store the value under
 * @param {any} value - The value to store
 */
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Get a value from localStorage
 * @param {string} key - The key to retrieve the value from
 * @returns {any} The retrieved value
 */
export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

/**
 * Scroll the generated form to the first field where validation occurs
 * @param {string} errorPath - the path of the input where the scroll should stop.
 * @param {string} formEditorRef - the reference of the generated form editor

 */
export const scrollToErrorOnForm = (errorPath, formEditorRef) => {
  const jsonEditorForm = document.getElementById("jsoneditor-form");
  const errorField = formEditorRef.current?.getEditor(errorPath);
  if (errorField) {
    const shouldScrollToTop = jsonEditorForm.scrollTop !== 0;
    errorField.container.scrollIntoView({ behavior: "smooth" });
    if (shouldScrollToTop) {
      setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 1000);
    }
  }
};

export const getProductIcon = (type) => {
  switch (type) {
    case "Desktop":
      return <DesktopOutlined />;
    case "Mobile":
      return <MobileOutlined />;
    case "Web":
      return <img src={WebIcon} width={15} height={15} />;
    default:
      return null;
  }
};

export const handleLinkClick = (event, url, navigate) => {
  if (event.ctrlKey || event.metaKey) {
    event.preventDefault();
    // Open in a new tab if Ctrl (Windows/Linux) or Cmd (macOS) is pressed
    window.open(url, "_blank", "noopener,noreferrer");
  } else if (event.button === 0) {
    // Normal left-click, prevent default and navigate within the app
    event.preventDefault();
    navigate(url);
  }
};
// JSON validation using JSON.parse
const validateJSON = (code) => {
  try {
    JSON.parse(code);
    return true;
  } catch (e) {
    return false;
  }
};

// Main validation function with language passed directly
export const validateCodeSyntax = (code, language) => {
  // Regular expressions for XAML and HTML detection
  const isXAML =
    /<(Window|Page|Grid|Button|TextBlock|StackPanel|UserControl)[\s>]/.test(
      code
    );
  const isHTML = true;

  let isValid = false;
  let message = "";

  // Validate based on passed language
  switch (language) {
    case "Json":
      isValid = validateJSON(code);
      message = isValid ? "Valid JSON" : "Invalid JSON syntax";
      break;

    case "Html":
      isValid = isHTML;
      message = isHTML
        ? isValid
          ? "Valid HTML"
          : "Invalid HTML syntax"
        : "Invalid HTML structure";
      break;

    case "Xaml":
      isValid = isXAML;
      message = isXAML
        ? isValid
          ? "Valid XAML"
          : "Invalid XAML syntax"
        : "Invalid XAML structure";
      break;

    case "text":
      isValid = true; // No syntax validation for plain text
      message = "Valid text";
      break;
    default:
      message = "Unsupported language for validation";
  }

  return { isValid, message };
};

export const replaceContentWithPlaceholders = (
  contentTemplate,
  placeholderArray = []
) => {
  let isValid = true;
  let replacedContent = JSON.stringify(contentTemplate); // Convert object to JSON string
  const validPlaceholders = placeholderArray?.map(
    (placeholder) => placeholder.name
  );
  const regex = /\{\{(.*?)\}\}/g; // Adjusted regex for {{placeholder}} pattern
  let match;
  let foundPlaceholders = [];

  // Find all placeholders in the content template
  while ((match = regex.exec(replacedContent)) !== null) {
    foundPlaceholders.push(match[1]); // Extract the placeholder name without brackets
  }

  // Replace placeholders with corresponding values from placeholderArray
  placeholderArray?.forEach((placeholder) => {
    const { name, defaultValue } = placeholder;
    const placeholderPattern = `{{${name}}}`; // Adjusted placeholder pattern

    // If the placeholder is found in the template
    if (replacedContent.includes(placeholderPattern)) {
      const valueToReplace =
        typeof defaultValue === "object"
          ? JSON.stringify(defaultValue) // Stringify objects for replacement
          : defaultValue; // Use non-object value directly

      // Replace all occurrences of the placeholder with the value
      replacedContent = replacedContent.replaceAll(
        placeholderPattern,
        valueToReplace
      );
    }
  });

  // Validate that all placeholders found in the content are valid
  foundPlaceholders.forEach((placeholder) => {
    if (!validPlaceholders.includes(placeholder)) {
      isValid = false;
    }
  });

  let parsedContent = {};
  try {
    // Try parsing the replaced content back into JSON
    parsedContent = JSON.parse(replacedContent);
  } catch (error) {
    // If parsing fails, set isValid to false
    isValid = false;
    console.error("Error parsing JSON after replacements: ", error);
  }

  return {
    isValid,
    replacedContent: parsedContent, // Return the parsed content
  };
};

// const NotificationTypes = {
//   JSON: "json",
//   HTML: "html",
//   XAML: "xaml",
// };

// export const getNotificationType = (type) => {
//   if (type) {
//     const normalizedType = type?.toUpperCase();
//     return NotificationTypes[normalizedType] || null;
//   }
// };

const StringTransformer = {
  UppercaseFirst: "UppercaseFirst",
  LowercaseFirst: "LowercaseFirst",
  UppercaseAll: "UppercaseAll",
  LowercaseAll: "LowercaseAll",
  ReverseString: "ReverseString",
  CapitalizeEachWord: "CapitalizeEachWord",
};

export const transformString = (transformType, value) => {
  if (value) {
    switch (transformType) {
      case StringTransformer.UppercaseFirst:
        return value?.charAt(0)?.toUpperCase() + value.slice(1);
      case StringTransformer.LowercaseFirst:
        return value?.charAt(0)?.toLowerCase() + value.slice(1);
      default:
        return value;
    }
  }
};

export const IsFileExtensionValid = (fileExtension) => {
  return Object.values(FileExtension).includes(fileExtension);
};

export const getPluralSuffix = (array) => {
  return array.length > 1 ? "s" : "";
};

export const removeWhitespaceFromXAML = (xaml) => {
  return xaml.replace(/\s+/g, " ").trim();
};

export const formatPhoneNumber = (apiResponse) => {
  if (!apiResponse) {
    return null;
  }
  const regex = /\((\d+)\)\s(\d{3})(\d{3})(\d{4})/;
  const match = apiResponse?.match(regex);

  if (!match) {
    throw new Error("Invalid phone number format");
  }

  const countryCode = match[1];
  const firstPart = match[2];
  const secondPart = match[3];
  const thirdPart = match[4];

  return `(${countryCode}) ${firstPart}-${secondPart}-${thirdPart}`;
};
