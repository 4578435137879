import React from "react";
import { Route, Routes } from "react-router-dom";
import ContentTemplatesListScreen from "./contentTemplatesListScreen";
import ContentTemplatesAddScreen from "./contentTemplatesAddScreen";
import Notfound from "../ErrorPages/notFound";
const ContentTemplatesRoute = (props) => {
  return (
    <>
      <React.Fragment>
        <Routes>
          <Route index path="/" element={<ContentTemplatesListScreen />} />
          <Route
            index
            path="/edit/:id"
            element={<ContentTemplatesAddScreen />}
          />
          <Route index path="/add" element={<ContentTemplatesAddScreen />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </React.Fragment>
    </>
  );
};

export default ContentTemplatesRoute;

