import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Space,
  Tag,
  Button,
  Input,
} from "antd";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import {
  handleKeyDownAllowOnlyNumber,
  isValidVersion,
} from "../../Util/commonUtility";
import ErrorMessages from "../../Constant/strings/errors";  
import ErrorToolTip from "./ErrorToolTip";
import { v4 as uuidv4 } from "uuid"; 

const CommonTagSelector = (props) => {
  const { initialValue, onTagChange, inputType, validationMessage } = props;
  const [selectedTag, setSelectedTag] = useState({ id: null, data: null, index: -1 });
  const [tagList, setTagList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTagValueChange = (value) => {
    setSelectedTag({ ...selectedTag, data: value });
    setErrorMessage("");
  };

  const handleTagClose = (removedTagId) => {
    const updatedTags = tagList.filter((tag) => tag.id !== removedTagId);
    onTagChange(updatedTags.map((tag) => tag.value));
    setTagList(updatedTags);
  };

  const handleTagClick = (clickedTag, index) => {
    setSelectedTag({ id: clickedTag.id, data: clickedTag.value, index: index });
  };

  const validateInput = (input, inputType) => {
    switch (inputType) {
      case "Version":
        if (!isValidVersion(input)) {
          return ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ERROR;
        }
        break;
      default:
        break;
    }
    return null;
  };

  const handleTagValueOnBlur = (value, inputType) => {
    const errorMessage = validateInput(value, inputType);
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage("");
  };

  const handleTagAdd = () => {
    if (selectedTag?.data) {
      const errorMessage = validateInput(selectedTag?.data, inputType);
      if (errorMessage) {
        setErrorMessage(errorMessage);
        return;
      }
      const existingTagIndex = selectedTag.index;
      if (existingTagIndex !== -1) {
        const updatedTags = [...tagList];
        updatedTags[existingTagIndex] = {
          id: selectedTag.id,
          value: selectedTag.data,
        };
        setTagList(updatedTags);
        onTagChange(updatedTags.map((tag) => tag.value));
      } else {
        const newTag = { id: uuidv4(), value: selectedTag.data };
        setTagList([...tagList, newTag]);
        onTagChange([...tagList, newTag].map((tag) => tag.value));
      }

      setSelectedTag({ id: null, data: null, index: -1 });
      setErrorMessage("");
    }
  };

  const renderInput = (inputType) => {
    switch (inputType) {
      case "Version":
        return (
          <Space.Compact direction="vertical">
            <Input
              value={selectedTag?.data || ""}
              onChange={(e) => handleTagValueChange(e.target.value)}
              onBlur={(e) => handleTagValueOnBlur(e.target.value, inputType)}
              placeholder={ErrorMessages.QUERYBUILDER.VERSION}
            />
            {errorMessage && <ErrorToolTip title={errorMessage} />}
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.VERSION.ADD}
              />
            )}
          </Space.Compact>
        );
      case "Input":
        return (
          <Space.Compact direction="vertical">
            <Input
              value={selectedTag?.data || ""}
              onChange={(e) => handleTagValueChange(e.target.value)}
              placeholder={ErrorMessages.QUERYBUILDER.STRING}
            />
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.STRING}
              />
            )}
          </Space.Compact>
        );
      case "InputNumber":
        return (
          <Space.Compact direction="vertical">
            <Input
              value={selectedTag?.data || ""}
              onChange={(e) => handleTagValueChange(e.target.value)}
              onKeyDown={handleKeyDownAllowOnlyNumber}
              placeholder={ErrorMessages.QUERYBUILDER.NUMBER}
            />
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.NUMBER}
              />
            )}
          </Space.Compact>
        );
      case "Date":
        return (
          <Space.Compact direction="vertical">
            <DatePicker
              value={selectedTag.data ? dayjs(selectedTag.data) : null}
              onChange={(date, dateString) => handleTagValueChange(dateString)}
            />
            {validationMessage && (
              <ErrorToolTip
                title={ErrorMessages.QUERYBUILDER.TAGSELECTOR.DATE}
              />
            )}
          </Space.Compact>
        );
      default:
        break;
    }
  };

  useEffect(()=>{
    setTagList(Array.isArray(initialValue)
    ? initialValue.map((value) => ({ id: uuidv4(), value }))
    : [])
  },[initialValue])

  return (
    <Space direction="horizontal">
      {renderInput(inputType)}
      <Button
        onClick={handleTagAdd}
        icon={<PlusOutlined />}
        title="Add"
      ></Button>
      <div>
        {tagList?.map((tag) => (
          <Tag
            cursorPointer
            color="volcano"
            key={tag?.id}
            closable
            onClose={() => handleTagClose(tag?.id)}
            onClick={() => handleTagClick(tag, tagList.indexOf(tag))}
          >
            {tag.value}
          </Tag>
        ))}
      </div>
    </Space>
  );
};

export default CommonTagSelector;
