import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Divider,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect } from "react";
import ShowDescriptionInListing from "../Common/showDescriptionInListing";
import ShowForRightAccessComponent from "../../Services/ShowForRightAccess";
import TotalRecordsInListingComponent from "../Common/totalRecordsInListing";
import CommonFilterForm from "../Common/commonFilterForm";
import { ContentTypeOptions, ENABLE_OPTIONS } from "../../Constant/Common";

const ListContentTemplatesComponent = (props) => {
  const {
    ContentTemplates,
    paging,
    handlePageChange,
    editContentTemplateData,
    deleteContentTemplate,
    listFilterSubmit,
    resetFilter,
    listFilterData,
  } = props;
  const [listFilter] = Form.useForm();

  useEffect(() => {
    listFilter.setFieldsValue(listFilterData);
  }, [listFilterData, listFilter]);

  // Adjusted filter fields to match the new data structure
  const filterFields = [
    {
      name: "name",
      component: (
        <Input className="list-filter-common-style" placeholder="Name" />
      ),
    },
    {
      name: "templateType",
      component: (
        <Select
          allowClear
          className="list-filter-common-style"
          placeholder="Template Type"
        >
          {ContentTypeOptions?.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: "IsEnable",
      component: (
        <Select
          allowClear
          className="list-filter-common-style select-target-min-width"
          placeholder="Is Enable?"
        >
          {ENABLE_OPTIONS?.map(({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  // Updated table columns based on the provided object structure
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <ShowDescriptionInListing title={text} text={text} />,
    },
    {
      title: "Template Type",
      dataIndex: "templateType",
      key: "templateType",
    },
    {
      title: "Languages",
      dataIndex: "languages",
      key: "languages",
      render: (languages) => (
        <>
          {languages?.map((lang) => (
            <Tag key={lang?.code} color="blue">
              {lang?.code?.toUpperCase()}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Enabled",
      dataIndex: "isEnable",
      key: "isEnable",
      render: (isEnable) => (isEnable ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <Space style={{ width: "200px" }}>
            <ShowForRightAccessComponent>
              <EditOutlined
                title="Edit"
                onClick={() => {
                  editContentTemplateData(record);
                }}
              />
              <Divider type="vertical" />
            </ShowForRightAccessComponent>
            <ShowForRightAccessComponent>
              <Popconfirm
                title="Are you sure to delete this content template?"
                onConfirm={() => {
                  deleteContentTemplate(record);
                }}
              >
                <DeleteOutlined title="Delete" />
              </Popconfirm>
            </ShowForRightAccessComponent>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CommonFilterForm
        filterFields={filterFields}
        onSubmit={listFilterSubmit}
        onReset={resetFilter}
        initialValues={listFilterData}
      />
      <Divider />
      <Table
        dataSource={ContentTemplates?.result}
        pagination={{
          showTotal: (total, range) => (
            <TotalRecordsInListingComponent
              totalRecords={paging.totalRecords}
            />
          ),
          pageSize: paging.pageSize,
          total: paging.totalPages * paging.pageSize,
          current: paging.pageIndex,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
        }}
        columns={columns}
        rowKey={"id"}
        size="small"
      />
    </>
  );
};

export default ListContentTemplatesComponent;

