import React from "react";
import { Tabs } from "antd";
import { handleLinkClick } from "../../Util/commonUtility";
import { useNavigate } from "react-router-dom";

const ProductInfoTabLayout = (props) => {
  const Navigate = useNavigate();
  const { selectedTabId, tabs, onTabChange, extraButton, copyButton } = props;
  const handleTabClick = (tab, event) => {
    handleLinkClick(event, tab?.url, Navigate);
  };
  return (
    <React.Fragment>
      <Tabs
        activeKey={selectedTabId}
        onClick={(key) => onTabChange(key)}
        tabBarExtraContent={
          <div className="flex gap-2">
            <div> {copyButton}</div>
            <div>{extraButton}</div>
          </div>
        }
      >
        {tabs.map((tab) => (
          <Tabs
            key={tab.key}
            tab={
              <a href={tab?.url} onClick={(e) => handleTabClick(tab, e)}>
                {tab.label}
              </a>
            }
          />
        ))}
        {props.childern}
      </Tabs>
    </React.Fragment>
  );
};

export default ProductInfoTabLayout;

