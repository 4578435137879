import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import { useCallback, useEffect, useState } from "react";
import Loader from "../../Components/Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import AddProductComponent from "../../Components/Products/AddProductsComponent";
import NotificationService from "../../Services/notification";

const AddProductsContainer = () => {
  const authState = useSelector((state) => state.auth);
  const [showLoading, setShowLoading] = useState(false);
  const [editProductData, setEditProductData] = useState();
  const navigate = useNavigate();
  const submitAddProductForm = async (data) => {
    setShowLoading(true);
    data.tenantId = authState?.accountData?.tenantId;
    if (data.id) {
      const response = await httpClient.put(
        Urls.TENANT_UPDATE_PRODUCT_BY_ID.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ).replace("#{productId}", data.id),
        data
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success("Success", "Product edit successfully");
        // resetEditFormState();
        // fetchAllTenantProducts();
        // SetSearchInput(undefined);
      } 
    } else {
      const response = await httpClient.post(
        Urls.TENANT_ADD_PRODUCT.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ),
        data
      );
      if (isSuccessStatus(response?.status)) {
        backToListPage();
        NotificationService.success("Success", "Product added successfully");
        // resetEditFormState();
        // fetchAllTenantProducts();
        // SetSearchInput(undefined);
      }
    }
    setShowLoading(false);
  };
  const { id } = useParams();
  const backToListPage = useCallback(() => {
    navigate("/products");
  }, [navigate]);

  useEffect(() => {
    const getProductDataById = async (id) => {
      setShowLoading(true);
      try {
        const response = await httpClient.get(
          `${Urls.TENANT_GET_PRODUCT_BY_ID.replace(
            "#{tenantId}",
            authState?.accountData?.tenantId
          ).replace("#{productId}", id)}`
        );

        if (isSuccessStatus(response?.status)) {
          setEditProductData(response?.data);
        } else {
        }
      } catch (error) {
        // Handle any potential error during API call
      } finally {
        setShowLoading(false);
      }
    };
    if (id) {
      getProductDataById(id);
    }
  }, [id, authState, backToListPage, navigate, setShowLoading]);

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <AddProductComponent
          onFinish={(data) => {
            submitAddProductForm(data);
          }}
          editProductData={editProductData}
          backToListPage={() => backToListPage()}
        />
      )}
    </>
  );
};
export default AddProductsContainer;
