import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../../Api/client";
import Urls from "../../../Constant/Urls";
import FileListPopUpComponent from "./../../../Components/ContentTemplates/FileList/fileListPopUp";

const FileListPopUpContainer = React.forwardRef((props, ref) => {
  const {
    setSelectedImageDefaultValue,
    currentImageDefaultValue,
    isEdit,
    placeHolderData,
    usedForRollout = false,
    formContentRollout,
    language,
    allFormValues,
    setAllFormValues,
  } = props;
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const [fileData, setFileData] = useState();
  const [loading, setLoading] = useState(false);
  const [isFetchAllFiles, setIsFetchAllFiles] = useState(false);

  const fetchAllFiles = async () => {
    try {
      setLoading(true);
      const response = await httpClient.get(
        `${Urls.TENANT_GET_ALL_FILE.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        ).replace(
          "#{productId}",
          productState?.selectedProduct?.id
        )}?PageSize=1000`
      );
      if (isSuccessStatus(response?.status)) {
        setFileData(response?.data?.result);
        // backToListPage();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchAllFiles) {
      fetchAllFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchAllFiles]);

  return (
    <>
      <FileListPopUpComponent
        fileData={fileData}
        currentImageDefaultValue={currentImageDefaultValue}
        setIsFetchAllFiles={setIsFetchAllFiles}
        setSelectedImageDefaultValue={setSelectedImageDefaultValue}
        isEdit={isEdit}
        placeHolderData={placeHolderData}
        usedForRollout={usedForRollout}
        showLocalLoader={loading}
        formContentRollout={formContentRollout}
        language={language}
        allFormValues={allFormValues}
        setAllFormValues={setAllFormValues}
      />
    </>
  );
});

export default FileListPopUpContainer;

