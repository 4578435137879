import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { httpClient, isSuccessStatus } from "../../Api/client";
import Urls from "../../Constant/Urls";
import Loader from "../../Components/Common/Loader";
import { DefaultPagingValue } from "../../Constant/Common";
import {
  convertDataToQueryParam,
  isNullOrEmpty,
} from "../../Util/commonUtility";
import NotificationService from "../../Services/notification";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../Hooks/useFetchData";
import { internalRoutes } from "../../Constant/internalRoutes";
import ListContentTemplatesComponent from "../../Components/ContentTemplates/listContentTemplatesComponent";

const ContentTemplatesContainer = React.forwardRef((props, ref) => {
  const authState = useSelector((state) => state.auth);
  const productState = useSelector((state) => state.product);
  const navigate = useNavigate();
  const [listFilterData, setListFilterData] = useState({});
  const [allContentTemplates, setAllContentTemplates] = useState([]);

  const [pagination, setPagination] = useState({
    pageSize: DefaultPagingValue.PAGE_SIZE || 10,
    pageIndex: DefaultPagingValue.PAGE_INDEX || 1,
    totalPages: 0,
    totalRecords: 0,
  });

  const {
    data: ContentTemplates,
    loading: showLoading,
    pagination: fetchedPagination,
  } = useFetchData(
    `${Urls.TENANT_GET_ALL_CONTENT_TEMPLATES.replace(
      "#{tenantId}",
      authState?.accountData?.tenantId
    )
      .replace("#{productId}", productState?.selectedProduct?.id)
      .replace(
        "#{environmentId}",
        productState?.selectedEnvironment?.id
      )}?${convertDataToQueryParam(listFilterData)}&PageNumber=${
      pagination.pageIndex
    }&PageSize=${pagination.pageSize}`,
    [
      pagination.pageIndex,
      pagination.pageSize,
      listFilterData,
      productState.selectedProduct?.id,
      productState.selectedEnvironment?.id,
    ]
  );

  useEffect(() => {
    if (fetchedPagination) {
      setPagination(fetchedPagination);
    }
  }, [fetchedPagination]);

  const editContentTemplateData = async (data) => {
    navigate(`${internalRoutes.contentTemplatesEdit}${data.id}`);
  };

  const deleteContentTemplate = async (data) => {
    const response = await httpClient.delete(
      Urls.TENANT_DELETE_CONTENT_TEMPLATE.replace(
        "#{tenantId}",
        authState?.accountData?.tenantId
      )
        .replace("#{productId}", productState?.selectedProduct?.id)
        .replace("#{environmentId}", productState?.selectedEnvironment?.id)
        .replace("#{templateId}", data.id)
    );
    if (isSuccessStatus(response?.status)) {
      NotificationService.success(
        "Success",
        "Content template deleted successfully."
      );
      setListFilterData({}); // Reset filter to reflect changes
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
      pageSize: pageSize,
    }));
  };

  const listFilterSubmit = (data) => {
    setListFilterData(data);
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter change
  };

  const resetFilter = () => {
    setListFilterData({});
    setPagination((prev) => ({ ...prev, pageIndex: 1 })); // Reset to first page on filter reset
  };

  const fetchAllContentTemplates = async () => {
    try {
      const response = await httpClient.get(
        `${Urls.TENANT_GET_ALL_CONTENT_TEMPLATES.replace(
          "#{tenantId}",
          authState?.accountData?.tenantId
        )
          .replace("#{productId}", productState?.selectedProduct?.id)
          .replace("#{environmentId}", productState?.selectedEnvironment?.id)}`
      );

      if (isSuccessStatus(response?.status) && !isNullOrEmpty(response?.data)) {
        setAllContentTemplates(response.data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllContentTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showLoading ? (
        <Loader />
      ) : (
        <ListContentTemplatesComponent
          ContentTemplates={ContentTemplates}
          listFilterData={listFilterData}
          paging={pagination}
          editContentTemplateData={(data) => editContentTemplateData(data)}
          deleteContentTemplate={(data) => deleteContentTemplate(data)}
          handlePageChange={handlePageChange}
          listFilterSubmit={listFilterSubmit}
          resetFilter={resetFilter}
          allContentTemplates={allContentTemplates}
        />
      )}
    </>
  );
});

export default ContentTemplatesContainer;

